import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  float8: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "canon_skill_years" */
export type CanonSkillsByYear = {
  /** An array relationship */
  canonSkills: Array<Sic29CanonSkill>;
  /** An aggregate relationship */
  canonSkills_aggregate: Sic29CanonSkill_Aggregate;
  year?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "canon_skill_years" */
export type CanonSkillsByYearCanonSkillsArgs = {
  distinct_on?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CanonSkill_Order_By>>;
  where?: Maybe<Sic29CanonSkill_Bool_Exp>;
};


/** columns and relationships of "canon_skill_years" */
export type CanonSkillsByYearCanonSkills_AggregateArgs = {
  distinct_on?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CanonSkill_Order_By>>;
  where?: Maybe<Sic29CanonSkill_Bool_Exp>;
};

/** aggregated selection of "canon_skill_years" */
export type CanonSkillsByYear_Aggregate = {
  aggregate?: Maybe<CanonSkillsByYear_Aggregate_Fields>;
  nodes: Array<CanonSkillsByYear>;
};

/** aggregate fields of "canon_skill_years" */
export type CanonSkillsByYear_Aggregate_Fields = {
  avg?: Maybe<CanonSkillsByYear_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CanonSkillsByYear_Max_Fields>;
  min?: Maybe<CanonSkillsByYear_Min_Fields>;
  stddev?: Maybe<CanonSkillsByYear_Stddev_Fields>;
  stddev_pop?: Maybe<CanonSkillsByYear_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CanonSkillsByYear_Stddev_Samp_Fields>;
  sum?: Maybe<CanonSkillsByYear_Sum_Fields>;
  var_pop?: Maybe<CanonSkillsByYear_Var_Pop_Fields>;
  var_samp?: Maybe<CanonSkillsByYear_Var_Samp_Fields>;
  variance?: Maybe<CanonSkillsByYear_Variance_Fields>;
};


/** aggregate fields of "canon_skill_years" */
export type CanonSkillsByYear_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CanonSkillsByYear_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CanonSkillsByYear_Avg_Fields = {
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "canon_skill_years". All fields are combined with a logical 'AND'. */
export type CanonSkillsByYear_Bool_Exp = {
  _and?: Maybe<Array<CanonSkillsByYear_Bool_Exp>>;
  _not?: Maybe<CanonSkillsByYear_Bool_Exp>;
  _or?: Maybe<Array<CanonSkillsByYear_Bool_Exp>>;
  canonSkills?: Maybe<Sic29CanonSkill_Bool_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type CanonSkillsByYear_Max_Fields = {
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type CanonSkillsByYear_Min_Fields = {
  year?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "canon_skill_years". */
export type CanonSkillsByYear_Order_By = {
  canonSkills_aggregate?: Maybe<Sic29CanonSkill_Aggregate_Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "canon_skill_years" */
export enum CanonSkillsByYear_Select_Column {
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type CanonSkillsByYear_Stddev_Fields = {
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CanonSkillsByYear_Stddev_Pop_Fields = {
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CanonSkillsByYear_Stddev_Samp_Fields = {
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CanonSkillsByYear_Sum_Fields = {
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type CanonSkillsByYear_Var_Pop_Fields = {
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CanonSkillsByYear_Var_Samp_Fields = {
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CanonSkillsByYear_Variance_Fields = {
  year?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['citext']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['citext']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['citext']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['citext']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['citext']>;
};

/** columns and relationships of "distinct_canon_skill" */
export type DistinctCanonSkill = {
  canonSkill?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "distinct_canon_skill". All fields are combined with a logical 'AND'. */
export type DistinctCanonSkill_Bool_Exp = {
  _and?: Maybe<Array<DistinctCanonSkill_Bool_Exp>>;
  _not?: Maybe<DistinctCanonSkill_Bool_Exp>;
  _or?: Maybe<Array<DistinctCanonSkill_Bool_Exp>>;
  canonSkill?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "distinct_canon_skill". */
export type DistinctCanonSkill_Order_By = {
  canonSkill?: Maybe<Order_By>;
};

/** select columns of table "distinct_canon_skill" */
export enum DistinctCanonSkill_Select_Column {
  /** column name */
  CanonSkill = 'canonSkill'
}

/** columns and relationships of "distinct_clean_job_title" */
export type DistinctCleanJobTitle = {
  cleanJobTitle?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "distinct_clean_job_title". All fields are combined with a logical 'AND'. */
export type DistinctCleanJobTitle_Bool_Exp = {
  _and?: Maybe<Array<DistinctCleanJobTitle_Bool_Exp>>;
  _not?: Maybe<DistinctCleanJobTitle_Bool_Exp>;
  _or?: Maybe<Array<DistinctCleanJobTitle_Bool_Exp>>;
  cleanJobTitle?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "distinct_clean_job_title". */
export type DistinctCleanJobTitle_Order_By = {
  cleanJobTitle?: Maybe<Order_By>;
};

/** select columns of table "distinct_clean_job_title" */
export enum DistinctCleanJobTitle_Select_Column {
  /** column name */
  CleanJobTitle = 'cleanJobTitle'
}


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  /** fetch data from the table: "canon_skill_years" */
  canonSkillsByYear: Array<CanonSkillsByYear>;
  /** fetch aggregated fields from the table: "canon_skill_years" */
  canonSkillsByYear_aggregate: CanonSkillsByYear_Aggregate;
  /** fetch data from the table: "distinct_canon_skill" */
  distinctCanonSkill: Array<DistinctCanonSkill>;
  /** fetch data from the table: "distinct_clean_job_title" */
  distinctCleanJobTitle: Array<DistinctCleanJobTitle>;
  /** fetch data from the table: "rolling_window_30_days_canon_skills" */
  rollingWindow30DaysCanonSkills: Array<RollingWindow30DaysCanonSkills>;
  /** fetch aggregated fields from the table: "rolling_window_30_days_canon_skills" */
  rollingWindow30DaysCanonSkillsAggregate: RollingWindow30DaysCanonSkills_Aggregate;
  /** fetch data from the table: "rolling_window_30_days_clean_job_title" */
  rollingWindow30DaysCleanJobTitle: Array<RollingWindow30DaysCleanJobTitle>;
  /** fetch aggregated fields from the table: "rolling_window_30_days_clean_job_title" */
  rollingWindow30DaysCleanJobTitleAggregate: RollingWindow30DaysCleanJobTitle_Aggregate;
  /** fetch data from the table: "sic_29_canon_skill" */
  sic29CanonSkill: Array<Sic29CanonSkill>;
  /** fetch aggregated fields from the table: "sic_29_canon_skill" */
  sic29CanonSkillAggregate: Sic29CanonSkill_Aggregate;
  /** fetch data from the table: "sic_29_clean_job_title" */
  sic29CleanJobTitle: Array<Sic29CleanJobTitle>;
  /** fetch aggregated fields from the table: "sic_29_clean_job_title" */
  sic29CleanJobTitleAggregate: Sic29CleanJobTitle_Aggregate;
  /** fetch data from the table: "sic_29_geo" */
  sic29Geo: Array<Sic29Geo>;
  /** fetch aggregated fields from the table: "sic_29_geo" */
  sic29GeoAggregate: Sic29Geo_Aggregate;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
};


export type Query_RootCanonSkillsByYearArgs = {
  distinct_on?: Maybe<Array<CanonSkillsByYear_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CanonSkillsByYear_Order_By>>;
  where?: Maybe<CanonSkillsByYear_Bool_Exp>;
};


export type Query_RootCanonSkillsByYear_AggregateArgs = {
  distinct_on?: Maybe<Array<CanonSkillsByYear_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CanonSkillsByYear_Order_By>>;
  where?: Maybe<CanonSkillsByYear_Bool_Exp>;
};


export type Query_RootDistinctCanonSkillArgs = {
  distinct_on?: Maybe<Array<DistinctCanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DistinctCanonSkill_Order_By>>;
  where?: Maybe<DistinctCanonSkill_Bool_Exp>;
};


export type Query_RootDistinctCleanJobTitleArgs = {
  distinct_on?: Maybe<Array<DistinctCleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DistinctCleanJobTitle_Order_By>>;
  where?: Maybe<DistinctCleanJobTitle_Bool_Exp>;
};


export type Query_RootRollingWindow30DaysCanonSkillsArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCanonSkills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCanonSkills_Order_By>>;
  where?: Maybe<RollingWindow30DaysCanonSkills_Bool_Exp>;
};


export type Query_RootRollingWindow30DaysCanonSkillsAggregateArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCanonSkills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCanonSkills_Order_By>>;
  where?: Maybe<RollingWindow30DaysCanonSkills_Bool_Exp>;
};


export type Query_RootRollingWindow30DaysCleanJobTitleArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Order_By>>;
  where?: Maybe<RollingWindow30DaysCleanJobTitle_Bool_Exp>;
};


export type Query_RootRollingWindow30DaysCleanJobTitleAggregateArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Order_By>>;
  where?: Maybe<RollingWindow30DaysCleanJobTitle_Bool_Exp>;
};


export type Query_RootSic29CanonSkillArgs = {
  distinct_on?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CanonSkill_Order_By>>;
  where?: Maybe<Sic29CanonSkill_Bool_Exp>;
};


export type Query_RootSic29CanonSkillAggregateArgs = {
  distinct_on?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CanonSkill_Order_By>>;
  where?: Maybe<Sic29CanonSkill_Bool_Exp>;
};


export type Query_RootSic29CleanJobTitleArgs = {
  distinct_on?: Maybe<Array<Sic29CleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CleanJobTitle_Order_By>>;
  where?: Maybe<Sic29CleanJobTitle_Bool_Exp>;
};


export type Query_RootSic29CleanJobTitleAggregateArgs = {
  distinct_on?: Maybe<Array<Sic29CleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CleanJobTitle_Order_By>>;
  where?: Maybe<Sic29CleanJobTitle_Bool_Exp>;
};


export type Query_RootSic29GeoArgs = {
  distinct_on?: Maybe<Array<Sic29Geo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29Geo_Order_By>>;
  where?: Maybe<Sic29Geo_Bool_Exp>;
};


export type Query_RootSic29GeoAggregateArgs = {
  distinct_on?: Maybe<Array<Sic29Geo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29Geo_Order_By>>;
  where?: Maybe<Sic29Geo_Bool_Exp>;
};


export type Query_RootUserArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "rolling_window_30_days_canon_skills" */
export type RollingWindow30DaysCanonSkills = {
  canonSkill?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "rolling_window_30_days_canon_skills" */
export type RollingWindow30DaysCanonSkills_Aggregate = {
  aggregate?: Maybe<RollingWindow30DaysCanonSkills_Aggregate_Fields>;
  nodes: Array<RollingWindow30DaysCanonSkills>;
};

/** aggregate fields of "rolling_window_30_days_canon_skills" */
export type RollingWindow30DaysCanonSkills_Aggregate_Fields = {
  avg?: Maybe<RollingWindow30DaysCanonSkills_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RollingWindow30DaysCanonSkills_Max_Fields>;
  min?: Maybe<RollingWindow30DaysCanonSkills_Min_Fields>;
  stddev?: Maybe<RollingWindow30DaysCanonSkills_Stddev_Fields>;
  stddev_pop?: Maybe<RollingWindow30DaysCanonSkills_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RollingWindow30DaysCanonSkills_Stddev_Samp_Fields>;
  sum?: Maybe<RollingWindow30DaysCanonSkills_Sum_Fields>;
  var_pop?: Maybe<RollingWindow30DaysCanonSkills_Var_Pop_Fields>;
  var_samp?: Maybe<RollingWindow30DaysCanonSkills_Var_Samp_Fields>;
  variance?: Maybe<RollingWindow30DaysCanonSkills_Variance_Fields>;
};


/** aggregate fields of "rolling_window_30_days_canon_skills" */
export type RollingWindow30DaysCanonSkills_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<RollingWindow30DaysCanonSkills_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RollingWindow30DaysCanonSkills_Avg_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rolling_window_30_days_canon_skills". All fields are combined with a logical 'AND'. */
export type RollingWindow30DaysCanonSkills_Bool_Exp = {
  _and?: Maybe<Array<RollingWindow30DaysCanonSkills_Bool_Exp>>;
  _not?: Maybe<RollingWindow30DaysCanonSkills_Bool_Exp>;
  _or?: Maybe<Array<RollingWindow30DaysCanonSkills_Bool_Exp>>;
  canonSkill?: Maybe<String_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  date?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RollingWindow30DaysCanonSkills_Max_Fields = {
  canonSkill?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type RollingWindow30DaysCanonSkills_Min_Fields = {
  canonSkill?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "rolling_window_30_days_canon_skills". */
export type RollingWindow30DaysCanonSkills_Order_By = {
  canonSkill?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "rolling_window_30_days_canon_skills" */
export enum RollingWindow30DaysCanonSkills_Select_Column {
  /** column name */
  CanonSkill = 'canonSkill',
  /** column name */
  Count = 'count',
  /** column name */
  Date = 'date',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type RollingWindow30DaysCanonSkills_Stddev_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RollingWindow30DaysCanonSkills_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RollingWindow30DaysCanonSkills_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RollingWindow30DaysCanonSkills_Sum_Fields = {
  count?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type RollingWindow30DaysCanonSkills_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RollingWindow30DaysCanonSkills_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RollingWindow30DaysCanonSkills_Variance_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rolling_window_30_days_clean_job_title" */
export type RollingWindow30DaysCleanJobTitle = {
  cleanJobTitle?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "rolling_window_30_days_clean_job_title" */
export type RollingWindow30DaysCleanJobTitle_Aggregate = {
  aggregate?: Maybe<RollingWindow30DaysCleanJobTitle_Aggregate_Fields>;
  nodes: Array<RollingWindow30DaysCleanJobTitle>;
};

/** aggregate fields of "rolling_window_30_days_clean_job_title" */
export type RollingWindow30DaysCleanJobTitle_Aggregate_Fields = {
  avg?: Maybe<RollingWindow30DaysCleanJobTitle_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RollingWindow30DaysCleanJobTitle_Max_Fields>;
  min?: Maybe<RollingWindow30DaysCleanJobTitle_Min_Fields>;
  stddev?: Maybe<RollingWindow30DaysCleanJobTitle_Stddev_Fields>;
  stddev_pop?: Maybe<RollingWindow30DaysCleanJobTitle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RollingWindow30DaysCleanJobTitle_Stddev_Samp_Fields>;
  sum?: Maybe<RollingWindow30DaysCleanJobTitle_Sum_Fields>;
  var_pop?: Maybe<RollingWindow30DaysCleanJobTitle_Var_Pop_Fields>;
  var_samp?: Maybe<RollingWindow30DaysCleanJobTitle_Var_Samp_Fields>;
  variance?: Maybe<RollingWindow30DaysCleanJobTitle_Variance_Fields>;
};


/** aggregate fields of "rolling_window_30_days_clean_job_title" */
export type RollingWindow30DaysCleanJobTitle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RollingWindow30DaysCleanJobTitle_Avg_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rolling_window_30_days_clean_job_title". All fields are combined with a logical 'AND'. */
export type RollingWindow30DaysCleanJobTitle_Bool_Exp = {
  _and?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Bool_Exp>>;
  _not?: Maybe<RollingWindow30DaysCleanJobTitle_Bool_Exp>;
  _or?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Bool_Exp>>;
  cleanJobTitle?: Maybe<String_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  date?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RollingWindow30DaysCleanJobTitle_Max_Fields = {
  cleanJobTitle?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type RollingWindow30DaysCleanJobTitle_Min_Fields = {
  cleanJobTitle?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "rolling_window_30_days_clean_job_title". */
export type RollingWindow30DaysCleanJobTitle_Order_By = {
  cleanJobTitle?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "rolling_window_30_days_clean_job_title" */
export enum RollingWindow30DaysCleanJobTitle_Select_Column {
  /** column name */
  CleanJobTitle = 'cleanJobTitle',
  /** column name */
  Count = 'count',
  /** column name */
  Date = 'date',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type RollingWindow30DaysCleanJobTitle_Stddev_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RollingWindow30DaysCleanJobTitle_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RollingWindow30DaysCleanJobTitle_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RollingWindow30DaysCleanJobTitle_Sum_Fields = {
  count?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type RollingWindow30DaysCleanJobTitle_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RollingWindow30DaysCleanJobTitle_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RollingWindow30DaysCleanJobTitle_Variance_Fields = {
  count?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sic_29_canon_skill" */
export type Sic29CanonSkill = {
  canonSkill?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "sic_29_canon_skill" */
export type Sic29CanonSkill_Aggregate = {
  aggregate?: Maybe<Sic29CanonSkill_Aggregate_Fields>;
  nodes: Array<Sic29CanonSkill>;
};

/** aggregate fields of "sic_29_canon_skill" */
export type Sic29CanonSkill_Aggregate_Fields = {
  avg?: Maybe<Sic29CanonSkill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sic29CanonSkill_Max_Fields>;
  min?: Maybe<Sic29CanonSkill_Min_Fields>;
  stddev?: Maybe<Sic29CanonSkill_Stddev_Fields>;
  stddev_pop?: Maybe<Sic29CanonSkill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sic29CanonSkill_Stddev_Samp_Fields>;
  sum?: Maybe<Sic29CanonSkill_Sum_Fields>;
  var_pop?: Maybe<Sic29CanonSkill_Var_Pop_Fields>;
  var_samp?: Maybe<Sic29CanonSkill_Var_Samp_Fields>;
  variance?: Maybe<Sic29CanonSkill_Variance_Fields>;
};


/** aggregate fields of "sic_29_canon_skill" */
export type Sic29CanonSkill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Aggregate_Order_By = {
  avg?: Maybe<Sic29CanonSkill_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sic29CanonSkill_Max_Order_By>;
  min?: Maybe<Sic29CanonSkill_Min_Order_By>;
  stddev?: Maybe<Sic29CanonSkill_Stddev_Order_By>;
  stddev_pop?: Maybe<Sic29CanonSkill_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sic29CanonSkill_Stddev_Samp_Order_By>;
  sum?: Maybe<Sic29CanonSkill_Sum_Order_By>;
  var_pop?: Maybe<Sic29CanonSkill_Var_Pop_Order_By>;
  var_samp?: Maybe<Sic29CanonSkill_Var_Samp_Order_By>;
  variance?: Maybe<Sic29CanonSkill_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Sic29CanonSkill_Avg_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Avg_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sic_29_canon_skill". All fields are combined with a logical 'AND'. */
export type Sic29CanonSkill_Bool_Exp = {
  _and?: Maybe<Array<Sic29CanonSkill_Bool_Exp>>;
  _not?: Maybe<Sic29CanonSkill_Bool_Exp>;
  _or?: Maybe<Array<Sic29CanonSkill_Bool_Exp>>;
  canonSkill?: Maybe<String_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  proportion?: Maybe<Float8_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Sic29CanonSkill_Max_Fields = {
  canonSkill?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Max_Order_By = {
  canonSkill?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sic29CanonSkill_Min_Fields = {
  canonSkill?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Min_Order_By = {
  canonSkill?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "sic_29_canon_skill". */
export type Sic29CanonSkill_Order_By = {
  canonSkill?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "sic_29_canon_skill" */
export enum Sic29CanonSkill_Select_Column {
  /** column name */
  CanonSkill = 'canonSkill',
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  Proportion = 'proportion',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Sic29CanonSkill_Stddev_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Stddev_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sic29CanonSkill_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sic29CanonSkill_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Sic29CanonSkill_Sum_Fields = {
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Sum_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Sic29CanonSkill_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sic29CanonSkill_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sic29CanonSkill_Variance_Fields = {
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sic_29_canon_skill" */
export type Sic29CanonSkill_Variance_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "sic_29_clean_job_title" */
export type Sic29CleanJobTitle = {
  cleanJobTitle?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "sic_29_clean_job_title" */
export type Sic29CleanJobTitle_Aggregate = {
  aggregate?: Maybe<Sic29CleanJobTitle_Aggregate_Fields>;
  nodes: Array<Sic29CleanJobTitle>;
};

/** aggregate fields of "sic_29_clean_job_title" */
export type Sic29CleanJobTitle_Aggregate_Fields = {
  avg?: Maybe<Sic29CleanJobTitle_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sic29CleanJobTitle_Max_Fields>;
  min?: Maybe<Sic29CleanJobTitle_Min_Fields>;
  stddev?: Maybe<Sic29CleanJobTitle_Stddev_Fields>;
  stddev_pop?: Maybe<Sic29CleanJobTitle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sic29CleanJobTitle_Stddev_Samp_Fields>;
  sum?: Maybe<Sic29CleanJobTitle_Sum_Fields>;
  var_pop?: Maybe<Sic29CleanJobTitle_Var_Pop_Fields>;
  var_samp?: Maybe<Sic29CleanJobTitle_Var_Samp_Fields>;
  variance?: Maybe<Sic29CleanJobTitle_Variance_Fields>;
};


/** aggregate fields of "sic_29_clean_job_title" */
export type Sic29CleanJobTitle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sic29CleanJobTitle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sic29CleanJobTitle_Avg_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sic_29_clean_job_title". All fields are combined with a logical 'AND'. */
export type Sic29CleanJobTitle_Bool_Exp = {
  _and?: Maybe<Array<Sic29CleanJobTitle_Bool_Exp>>;
  _not?: Maybe<Sic29CleanJobTitle_Bool_Exp>;
  _or?: Maybe<Array<Sic29CleanJobTitle_Bool_Exp>>;
  cleanJobTitle?: Maybe<String_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  proportion?: Maybe<Float8_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Sic29CleanJobTitle_Max_Fields = {
  cleanJobTitle?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Sic29CleanJobTitle_Min_Fields = {
  cleanJobTitle?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "sic_29_clean_job_title". */
export type Sic29CleanJobTitle_Order_By = {
  cleanJobTitle?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "sic_29_clean_job_title" */
export enum Sic29CleanJobTitle_Select_Column {
  /** column name */
  CleanJobTitle = 'cleanJobTitle',
  /** column name */
  Count = 'count',
  /** column name */
  Proportion = 'proportion',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Sic29CleanJobTitle_Stddev_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sic29CleanJobTitle_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sic29CleanJobTitle_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Sic29CleanJobTitle_Sum_Fields = {
  count?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Sic29CleanJobTitle_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sic29CleanJobTitle_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sic29CleanJobTitle_Variance_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sic_29_geo" */
export type Sic29Geo = {
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  proportion?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "sic_29_geo" */
export type Sic29Geo_Aggregate = {
  aggregate?: Maybe<Sic29Geo_Aggregate_Fields>;
  nodes: Array<Sic29Geo>;
};

/** aggregate fields of "sic_29_geo" */
export type Sic29Geo_Aggregate_Fields = {
  avg?: Maybe<Sic29Geo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sic29Geo_Max_Fields>;
  min?: Maybe<Sic29Geo_Min_Fields>;
  stddev?: Maybe<Sic29Geo_Stddev_Fields>;
  stddev_pop?: Maybe<Sic29Geo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sic29Geo_Stddev_Samp_Fields>;
  sum?: Maybe<Sic29Geo_Sum_Fields>;
  var_pop?: Maybe<Sic29Geo_Var_Pop_Fields>;
  var_samp?: Maybe<Sic29Geo_Var_Samp_Fields>;
  variance?: Maybe<Sic29Geo_Variance_Fields>;
};


/** aggregate fields of "sic_29_geo" */
export type Sic29Geo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sic29Geo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sic29Geo_Avg_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sic_29_geo". All fields are combined with a logical 'AND'. */
export type Sic29Geo_Bool_Exp = {
  _and?: Maybe<Array<Sic29Geo_Bool_Exp>>;
  _not?: Maybe<Sic29Geo_Bool_Exp>;
  _or?: Maybe<Array<Sic29Geo_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  proportion?: Maybe<Float8_Comparison_Exp>;
  unit?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Sic29Geo_Max_Fields = {
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  proportion?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Sic29Geo_Min_Fields = {
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  proportion?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "sic_29_geo". */
export type Sic29Geo_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "sic_29_geo" */
export enum Sic29Geo_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Proportion = 'proportion',
  /** column name */
  Unit = 'unit',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Sic29Geo_Stddev_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sic29Geo_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sic29Geo_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Sic29Geo_Sum_Fields = {
  count?: Maybe<Scalars['bigint']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Sic29Geo_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sic29Geo_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sic29Geo_Variance_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  /** fetch data from the table: "canon_skill_years" */
  canonSkillsByYear: Array<CanonSkillsByYear>;
  /** fetch aggregated fields from the table: "canon_skill_years" */
  canonSkillsByYear_aggregate: CanonSkillsByYear_Aggregate;
  /** fetch data from the table: "distinct_canon_skill" */
  distinctCanonSkill: Array<DistinctCanonSkill>;
  /** fetch data from the table: "distinct_clean_job_title" */
  distinctCleanJobTitle: Array<DistinctCleanJobTitle>;
  /** fetch data from the table: "rolling_window_30_days_canon_skills" */
  rollingWindow30DaysCanonSkills: Array<RollingWindow30DaysCanonSkills>;
  /** fetch aggregated fields from the table: "rolling_window_30_days_canon_skills" */
  rollingWindow30DaysCanonSkillsAggregate: RollingWindow30DaysCanonSkills_Aggregate;
  /** fetch data from the table: "rolling_window_30_days_clean_job_title" */
  rollingWindow30DaysCleanJobTitle: Array<RollingWindow30DaysCleanJobTitle>;
  /** fetch aggregated fields from the table: "rolling_window_30_days_clean_job_title" */
  rollingWindow30DaysCleanJobTitleAggregate: RollingWindow30DaysCleanJobTitle_Aggregate;
  /** fetch data from the table: "sic_29_canon_skill" */
  sic29CanonSkill: Array<Sic29CanonSkill>;
  /** fetch aggregated fields from the table: "sic_29_canon_skill" */
  sic29CanonSkillAggregate: Sic29CanonSkill_Aggregate;
  /** fetch data from the table: "sic_29_clean_job_title" */
  sic29CleanJobTitle: Array<Sic29CleanJobTitle>;
  /** fetch aggregated fields from the table: "sic_29_clean_job_title" */
  sic29CleanJobTitleAggregate: Sic29CleanJobTitle_Aggregate;
  /** fetch data from the table: "sic_29_geo" */
  sic29Geo: Array<Sic29Geo>;
  /** fetch aggregated fields from the table: "sic_29_geo" */
  sic29GeoAggregate: Sic29Geo_Aggregate;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
};


export type Subscription_RootCanonSkillsByYearArgs = {
  distinct_on?: Maybe<Array<CanonSkillsByYear_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CanonSkillsByYear_Order_By>>;
  where?: Maybe<CanonSkillsByYear_Bool_Exp>;
};


export type Subscription_RootCanonSkillsByYear_AggregateArgs = {
  distinct_on?: Maybe<Array<CanonSkillsByYear_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CanonSkillsByYear_Order_By>>;
  where?: Maybe<CanonSkillsByYear_Bool_Exp>;
};


export type Subscription_RootDistinctCanonSkillArgs = {
  distinct_on?: Maybe<Array<DistinctCanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DistinctCanonSkill_Order_By>>;
  where?: Maybe<DistinctCanonSkill_Bool_Exp>;
};


export type Subscription_RootDistinctCleanJobTitleArgs = {
  distinct_on?: Maybe<Array<DistinctCleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DistinctCleanJobTitle_Order_By>>;
  where?: Maybe<DistinctCleanJobTitle_Bool_Exp>;
};


export type Subscription_RootRollingWindow30DaysCanonSkillsArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCanonSkills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCanonSkills_Order_By>>;
  where?: Maybe<RollingWindow30DaysCanonSkills_Bool_Exp>;
};


export type Subscription_RootRollingWindow30DaysCanonSkillsAggregateArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCanonSkills_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCanonSkills_Order_By>>;
  where?: Maybe<RollingWindow30DaysCanonSkills_Bool_Exp>;
};


export type Subscription_RootRollingWindow30DaysCleanJobTitleArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Order_By>>;
  where?: Maybe<RollingWindow30DaysCleanJobTitle_Bool_Exp>;
};


export type Subscription_RootRollingWindow30DaysCleanJobTitleAggregateArgs = {
  distinct_on?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RollingWindow30DaysCleanJobTitle_Order_By>>;
  where?: Maybe<RollingWindow30DaysCleanJobTitle_Bool_Exp>;
};


export type Subscription_RootSic29CanonSkillArgs = {
  distinct_on?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CanonSkill_Order_By>>;
  where?: Maybe<Sic29CanonSkill_Bool_Exp>;
};


export type Subscription_RootSic29CanonSkillAggregateArgs = {
  distinct_on?: Maybe<Array<Sic29CanonSkill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CanonSkill_Order_By>>;
  where?: Maybe<Sic29CanonSkill_Bool_Exp>;
};


export type Subscription_RootSic29CleanJobTitleArgs = {
  distinct_on?: Maybe<Array<Sic29CleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CleanJobTitle_Order_By>>;
  where?: Maybe<Sic29CleanJobTitle_Bool_Exp>;
};


export type Subscription_RootSic29CleanJobTitleAggregateArgs = {
  distinct_on?: Maybe<Array<Sic29CleanJobTitle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29CleanJobTitle_Order_By>>;
  where?: Maybe<Sic29CleanJobTitle_Bool_Exp>;
};


export type Subscription_RootSic29GeoArgs = {
  distinct_on?: Maybe<Array<Sic29Geo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29Geo_Order_By>>;
  where?: Maybe<Sic29Geo_Bool_Exp>;
};


export type Subscription_RootSic29GeoAggregateArgs = {
  distinct_on?: Maybe<Array<Sic29Geo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sic29Geo_Order_By>>;
  where?: Maybe<Sic29Geo_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  activated?: Maybe<Scalars['Boolean']>;
  avatarUrl: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  defaultRole: Scalars['String'];
  disabled: Scalars['Boolean'];
  displayName: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  emailVerified: Scalars['Boolean'];
  id: Scalars['uuid'];
  isAnonymous: Scalars['Boolean'];
  lastSeen?: Maybe<Scalars['timestamptz']>;
  locale: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  phoneNumber?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  activated?: Maybe<Boolean_Comparison_Exp>;
  avatarUrl?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  defaultRole?: Maybe<String_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  email?: Maybe<Citext_Comparison_Exp>;
  emailVerified?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isAnonymous?: Maybe<Boolean_Comparison_Exp>;
  lastSeen?: Maybe<Timestamptz_Comparison_Exp>;
  locale?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  phoneNumber?: Maybe<String_Comparison_Exp>;
  ticket?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Users_Order_By = {
  activated?: Maybe<Order_By>;
  avatarUrl?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  defaultRole?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailVerified?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isAnonymous?: Maybe<Order_By>;
  lastSeen?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** select columns of table "auth.users" */
export enum Users_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  UpdatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GeoDataQueryVariables = Exact<{
  unit?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
}>;


export type GeoDataQuery = { sic29GeoAggregate: { aggregate?: Maybe<{ max?: Maybe<Pick<Sic29Geo_Max_Fields, 'count'>>, min?: Maybe<Pick<Sic29Geo_Min_Fields, 'count'>> }> }, sic29Geo: Array<Pick<Sic29Geo, 'id' | 'name' | 'count' | 'unit' | 'proportion'>> };

export type CanonSkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type CanonSkillsQuery = { distinctCanonSkill: Array<Pick<DistinctCanonSkill, 'canonSkill'>> };

export type StatsByYearQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsByYearQuery = { canonSkillsByYear: Array<{ canonSkills: Array<(
      Pick<Sic29CanonSkill, 'count' | 'year'>
      & { name: Sic29CanonSkill['canonSkill'] }
    )> }> };

export type RollingWindowQueryVariables = Exact<{
  date: Scalars['String'];
  prevDate: Scalars['String'];
}>;


export type RollingWindowQuery = { skills: Array<(
    Pick<RollingWindow30DaysCanonSkills, 'count' | 'date'>
    & { name: RollingWindow30DaysCanonSkills['canonSkill'] }
  )>, prevSkills: Array<(
    Pick<RollingWindow30DaysCanonSkills, 'count' | 'date'>
    & { name: RollingWindow30DaysCanonSkills['canonSkill'] }
  )> };

export type YearRangeQueryVariables = Exact<{ [key: string]: never; }>;


export type YearRangeQuery = { geo: { aggregate?: Maybe<{ min?: Maybe<Pick<Sic29Geo_Min_Fields, 'year'>>, max?: Maybe<Pick<Sic29Geo_Max_Fields, 'year'>> }> }, rolling: { aggregate?: Maybe<{ min?: Maybe<Pick<RollingWindow30DaysCanonSkills_Min_Fields, 'year' | 'date'>>, max?: Maybe<Pick<RollingWindow30DaysCanonSkills_Max_Fields, 'year' | 'date'>> }> } };

export type YearlyStatsQueryVariables = Exact<{
  year: Scalars['bigint'];
  prevYear: Scalars['bigint'];
}>;


export type YearlyStatsQuery = { skills: Array<(
    Pick<Sic29CanonSkill, 'count' | 'year'>
    & { name: Sic29CanonSkill['canonSkill'] }
  )>, prevSkills: Array<(
    Pick<Sic29CanonSkill, 'count' | 'year'>
    & { name: Sic29CanonSkill['canonSkill'] }
  )> };

export type UserQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type UserQuery = { user?: Maybe<Pick<Users, 'id' | 'displayName' | 'phoneNumber' | 'activated' | 'metadata' | 'email' | 'createdAt'>> };


export const GeoDataDocument = gql`
    query GeoData($unit: String, $year: bigint) {
  sic29GeoAggregate(where: {unit: {_eq: $unit}}) {
    aggregate {
      max {
        count
      }
      min {
        count
      }
    }
  }
  sic29Geo(
    where: {_and: [{unit: {_eq: $unit}}, {year: {_eq: $year}}]}
    order_by: {count: asc}
  ) {
    id
    name
    count
    unit
    proportion
  }
}
    `;

/**
 * __useGeoDataQuery__
 *
 * To run a query within a React component, call `useGeoDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoDataQuery({
 *   variables: {
 *      unit: // value for 'unit'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGeoDataQuery(baseOptions?: Apollo.QueryHookOptions<GeoDataQuery, GeoDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeoDataQuery, GeoDataQueryVariables>(GeoDataDocument, options);
      }
export function useGeoDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeoDataQuery, GeoDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeoDataQuery, GeoDataQueryVariables>(GeoDataDocument, options);
        }
export type GeoDataQueryHookResult = ReturnType<typeof useGeoDataQuery>;
export type GeoDataLazyQueryHookResult = ReturnType<typeof useGeoDataLazyQuery>;
export type GeoDataQueryResult = Apollo.QueryResult<GeoDataQuery, GeoDataQueryVariables>;
export const CanonSkillsDocument = gql`
    query CanonSkills {
  distinctCanonSkill {
    canonSkill
  }
}
    `;

/**
 * __useCanonSkillsQuery__
 *
 * To run a query within a React component, call `useCanonSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanonSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanonSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanonSkillsQuery(baseOptions?: Apollo.QueryHookOptions<CanonSkillsQuery, CanonSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanonSkillsQuery, CanonSkillsQueryVariables>(CanonSkillsDocument, options);
      }
export function useCanonSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanonSkillsQuery, CanonSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanonSkillsQuery, CanonSkillsQueryVariables>(CanonSkillsDocument, options);
        }
export type CanonSkillsQueryHookResult = ReturnType<typeof useCanonSkillsQuery>;
export type CanonSkillsLazyQueryHookResult = ReturnType<typeof useCanonSkillsLazyQuery>;
export type CanonSkillsQueryResult = Apollo.QueryResult<CanonSkillsQuery, CanonSkillsQueryVariables>;
export const StatsByYearDocument = gql`
    query StatsByYear {
  canonSkillsByYear {
    canonSkills(order_by: {count: desc}, limit: 10) {
      name: canonSkill
      count
      year
    }
  }
}
    `;

/**
 * __useStatsByYearQuery__
 *
 * To run a query within a React component, call `useStatsByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsByYearQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsByYearQuery(baseOptions?: Apollo.QueryHookOptions<StatsByYearQuery, StatsByYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatsByYearQuery, StatsByYearQueryVariables>(StatsByYearDocument, options);
      }
export function useStatsByYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatsByYearQuery, StatsByYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatsByYearQuery, StatsByYearQueryVariables>(StatsByYearDocument, options);
        }
export type StatsByYearQueryHookResult = ReturnType<typeof useStatsByYearQuery>;
export type StatsByYearLazyQueryHookResult = ReturnType<typeof useStatsByYearLazyQuery>;
export type StatsByYearQueryResult = Apollo.QueryResult<StatsByYearQuery, StatsByYearQueryVariables>;
export const RollingWindowDocument = gql`
    query RollingWindow($date: String!, $prevDate: String!) {
  skills: rollingWindow30DaysCanonSkills(
    where: {date: {_eq: $date}}
    order_by: {count: desc}
    limit: 10
  ) {
    name: canonSkill
    count
    date
  }
  prevSkills: rollingWindow30DaysCanonSkills(
    where: {date: {_eq: $prevDate}}
    order_by: {count: desc}
    limit: 10
  ) {
    name: canonSkill
    count
    date
  }
}
    `;

/**
 * __useRollingWindowQuery__
 *
 * To run a query within a React component, call `useRollingWindowQuery` and pass it any options that fit your needs.
 * When your component renders, `useRollingWindowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRollingWindowQuery({
 *   variables: {
 *      date: // value for 'date'
 *      prevDate: // value for 'prevDate'
 *   },
 * });
 */
export function useRollingWindowQuery(baseOptions: Apollo.QueryHookOptions<RollingWindowQuery, RollingWindowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RollingWindowQuery, RollingWindowQueryVariables>(RollingWindowDocument, options);
      }
export function useRollingWindowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RollingWindowQuery, RollingWindowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RollingWindowQuery, RollingWindowQueryVariables>(RollingWindowDocument, options);
        }
export type RollingWindowQueryHookResult = ReturnType<typeof useRollingWindowQuery>;
export type RollingWindowLazyQueryHookResult = ReturnType<typeof useRollingWindowLazyQuery>;
export type RollingWindowQueryResult = Apollo.QueryResult<RollingWindowQuery, RollingWindowQueryVariables>;
export const YearRangeDocument = gql`
    query YearRange {
  geo: sic29GeoAggregate {
    aggregate {
      min {
        year
      }
      max {
        year
      }
    }
  }
  rolling: rollingWindow30DaysCanonSkillsAggregate {
    aggregate {
      min {
        year
        date
      }
      max {
        year
        date
      }
    }
  }
}
    `;

/**
 * __useYearRangeQuery__
 *
 * To run a query within a React component, call `useYearRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearRangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useYearRangeQuery(baseOptions?: Apollo.QueryHookOptions<YearRangeQuery, YearRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YearRangeQuery, YearRangeQueryVariables>(YearRangeDocument, options);
      }
export function useYearRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YearRangeQuery, YearRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YearRangeQuery, YearRangeQueryVariables>(YearRangeDocument, options);
        }
export type YearRangeQueryHookResult = ReturnType<typeof useYearRangeQuery>;
export type YearRangeLazyQueryHookResult = ReturnType<typeof useYearRangeLazyQuery>;
export type YearRangeQueryResult = Apollo.QueryResult<YearRangeQuery, YearRangeQueryVariables>;
export const YearlyStatsDocument = gql`
    query YearlyStats($year: bigint!, $prevYear: bigint!) {
  skills: sic29CanonSkill(
    where: {year: {_eq: $year}}
    order_by: {count: desc}
    limit: 10
  ) {
    name: canonSkill
    count
    year
  }
  prevSkills: sic29CanonSkill(
    where: {year: {_eq: $prevYear}}
    order_by: {count: desc}
    limit: 10
  ) {
    name: canonSkill
    count
    year
  }
}
    `;

/**
 * __useYearlyStatsQuery__
 *
 * To run a query within a React component, call `useYearlyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyStatsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      prevYear: // value for 'prevYear'
 *   },
 * });
 */
export function useYearlyStatsQuery(baseOptions: Apollo.QueryHookOptions<YearlyStatsQuery, YearlyStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YearlyStatsQuery, YearlyStatsQueryVariables>(YearlyStatsDocument, options);
      }
export function useYearlyStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YearlyStatsQuery, YearlyStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YearlyStatsQuery, YearlyStatsQueryVariables>(YearlyStatsDocument, options);
        }
export type YearlyStatsQueryHookResult = ReturnType<typeof useYearlyStatsQuery>;
export type YearlyStatsLazyQueryHookResult = ReturnType<typeof useYearlyStatsLazyQuery>;
export type YearlyStatsQueryResult = Apollo.QueryResult<YearlyStatsQuery, YearlyStatsQueryVariables>;
export const UserDocument = gql`
    query User($user_id: uuid!) {
  user(id: $user_id) {
    id
    displayName
    phoneNumber
    activated
    metadata
    email
    createdAt
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export type canonSkillsByYearKeySpecifier = ('canonSkills' | 'canonSkills_aggregate' | 'year' | canonSkillsByYearKeySpecifier)[];
export type canonSkillsByYearFieldPolicy = {
	canonSkills?: FieldPolicy<any> | FieldReadFunction<any>,
	canonSkills_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_aggregateKeySpecifier = ('aggregate' | 'nodes' | canonSkillsByYear_aggregateKeySpecifier)[];
export type canonSkillsByYear_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | canonSkillsByYear_aggregate_fieldsKeySpecifier)[];
export type canonSkillsByYear_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_avg_fieldsKeySpecifier = ('year' | canonSkillsByYear_avg_fieldsKeySpecifier)[];
export type canonSkillsByYear_avg_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_max_fieldsKeySpecifier = ('year' | canonSkillsByYear_max_fieldsKeySpecifier)[];
export type canonSkillsByYear_max_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_min_fieldsKeySpecifier = ('year' | canonSkillsByYear_min_fieldsKeySpecifier)[];
export type canonSkillsByYear_min_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_stddev_fieldsKeySpecifier = ('year' | canonSkillsByYear_stddev_fieldsKeySpecifier)[];
export type canonSkillsByYear_stddev_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_stddev_pop_fieldsKeySpecifier = ('year' | canonSkillsByYear_stddev_pop_fieldsKeySpecifier)[];
export type canonSkillsByYear_stddev_pop_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_stddev_samp_fieldsKeySpecifier = ('year' | canonSkillsByYear_stddev_samp_fieldsKeySpecifier)[];
export type canonSkillsByYear_stddev_samp_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_sum_fieldsKeySpecifier = ('year' | canonSkillsByYear_sum_fieldsKeySpecifier)[];
export type canonSkillsByYear_sum_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_var_pop_fieldsKeySpecifier = ('year' | canonSkillsByYear_var_pop_fieldsKeySpecifier)[];
export type canonSkillsByYear_var_pop_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_var_samp_fieldsKeySpecifier = ('year' | canonSkillsByYear_var_samp_fieldsKeySpecifier)[];
export type canonSkillsByYear_var_samp_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type canonSkillsByYear_variance_fieldsKeySpecifier = ('year' | canonSkillsByYear_variance_fieldsKeySpecifier)[];
export type canonSkillsByYear_variance_fieldsFieldPolicy = {
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type distinctCanonSkillKeySpecifier = ('canonSkill' | distinctCanonSkillKeySpecifier)[];
export type distinctCanonSkillFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type distinctCleanJobTitleKeySpecifier = ('cleanJobTitle' | distinctCleanJobTitleKeySpecifier)[];
export type distinctCleanJobTitleFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type query_rootKeySpecifier = ('canonSkillsByYear' | 'canonSkillsByYear_aggregate' | 'distinctCanonSkill' | 'distinctCleanJobTitle' | 'rollingWindow30DaysCanonSkills' | 'rollingWindow30DaysCanonSkillsAggregate' | 'rollingWindow30DaysCleanJobTitle' | 'rollingWindow30DaysCleanJobTitleAggregate' | 'sic29CanonSkill' | 'sic29CanonSkillAggregate' | 'sic29CleanJobTitle' | 'sic29CleanJobTitleAggregate' | 'sic29Geo' | 'sic29GeoAggregate' | 'user' | 'users' | query_rootKeySpecifier)[];
export type query_rootFieldPolicy = {
	canonSkillsByYear?: FieldPolicy<any> | FieldReadFunction<any>,
	canonSkillsByYear_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	distinctCanonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	distinctCleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCanonSkills?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCanonSkillsAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCleanJobTitleAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CanonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CanonSkillAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CleanJobTitleAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29Geo?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29GeoAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkillsKeySpecifier = ('canonSkill' | 'count' | 'date' | 'year' | rollingWindow30DaysCanonSkillsKeySpecifier)[];
export type rollingWindow30DaysCanonSkillsFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_aggregateKeySpecifier = ('aggregate' | 'nodes' | rollingWindow30DaysCanonSkills_aggregateKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | rollingWindow30DaysCanonSkills_aggregate_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_avg_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_avg_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_avg_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_max_fieldsKeySpecifier = ('canonSkill' | 'count' | 'date' | 'year' | rollingWindow30DaysCanonSkills_max_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_max_fieldsFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_min_fieldsKeySpecifier = ('canonSkill' | 'count' | 'date' | 'year' | rollingWindow30DaysCanonSkills_min_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_min_fieldsFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_stddev_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_stddev_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_stddev_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_stddev_pop_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_stddev_pop_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_stddev_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_stddev_samp_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_stddev_samp_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_stddev_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_sum_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_sum_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_sum_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_var_pop_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_var_pop_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_var_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_var_samp_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_var_samp_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_var_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCanonSkills_variance_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCanonSkills_variance_fieldsKeySpecifier)[];
export type rollingWindow30DaysCanonSkills_variance_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitleKeySpecifier = ('cleanJobTitle' | 'count' | 'date' | 'year' | rollingWindow30DaysCleanJobTitleKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitleFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_aggregateKeySpecifier = ('aggregate' | 'nodes' | rollingWindow30DaysCleanJobTitle_aggregateKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | rollingWindow30DaysCleanJobTitle_aggregate_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_avg_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_avg_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_avg_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_max_fieldsKeySpecifier = ('cleanJobTitle' | 'count' | 'date' | 'year' | rollingWindow30DaysCleanJobTitle_max_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_max_fieldsFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_min_fieldsKeySpecifier = ('cleanJobTitle' | 'count' | 'date' | 'year' | rollingWindow30DaysCleanJobTitle_min_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_min_fieldsFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_stddev_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_stddev_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_stddev_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_stddev_pop_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_stddev_pop_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_stddev_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_stddev_samp_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_stddev_samp_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_stddev_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_sum_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_sum_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_sum_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_var_pop_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_var_pop_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_var_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_var_samp_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_var_samp_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_var_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type rollingWindow30DaysCleanJobTitle_variance_fieldsKeySpecifier = ('count' | 'year' | rollingWindow30DaysCleanJobTitle_variance_fieldsKeySpecifier)[];
export type rollingWindow30DaysCleanJobTitle_variance_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkillKeySpecifier = ('canonSkill' | 'count' | 'id' | 'proportion' | 'year' | sic29CanonSkillKeySpecifier)[];
export type sic29CanonSkillFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_aggregateKeySpecifier = ('aggregate' | 'nodes' | sic29CanonSkill_aggregateKeySpecifier)[];
export type sic29CanonSkill_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | sic29CanonSkill_aggregate_fieldsKeySpecifier)[];
export type sic29CanonSkill_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_avg_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_avg_fieldsKeySpecifier)[];
export type sic29CanonSkill_avg_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_max_fieldsKeySpecifier = ('canonSkill' | 'count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_max_fieldsKeySpecifier)[];
export type sic29CanonSkill_max_fieldsFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_min_fieldsKeySpecifier = ('canonSkill' | 'count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_min_fieldsKeySpecifier)[];
export type sic29CanonSkill_min_fieldsFieldPolicy = {
	canonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_stddev_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_stddev_fieldsKeySpecifier)[];
export type sic29CanonSkill_stddev_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_stddev_pop_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_stddev_pop_fieldsKeySpecifier)[];
export type sic29CanonSkill_stddev_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_stddev_samp_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_stddev_samp_fieldsKeySpecifier)[];
export type sic29CanonSkill_stddev_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_sum_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_sum_fieldsKeySpecifier)[];
export type sic29CanonSkill_sum_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_var_pop_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_var_pop_fieldsKeySpecifier)[];
export type sic29CanonSkill_var_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_var_samp_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_var_samp_fieldsKeySpecifier)[];
export type sic29CanonSkill_var_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CanonSkill_variance_fieldsKeySpecifier = ('count' | 'id' | 'proportion' | 'year' | sic29CanonSkill_variance_fieldsKeySpecifier)[];
export type sic29CanonSkill_variance_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitleKeySpecifier = ('cleanJobTitle' | 'count' | 'proportion' | 'year' | sic29CleanJobTitleKeySpecifier)[];
export type sic29CleanJobTitleFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_aggregateKeySpecifier = ('aggregate' | 'nodes' | sic29CleanJobTitle_aggregateKeySpecifier)[];
export type sic29CleanJobTitle_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | sic29CleanJobTitle_aggregate_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_avg_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_avg_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_avg_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_max_fieldsKeySpecifier = ('cleanJobTitle' | 'count' | 'proportion' | 'year' | sic29CleanJobTitle_max_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_max_fieldsFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_min_fieldsKeySpecifier = ('cleanJobTitle' | 'count' | 'proportion' | 'year' | sic29CleanJobTitle_min_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_min_fieldsFieldPolicy = {
	cleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_stddev_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_stddev_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_stddev_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_stddev_pop_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_stddev_pop_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_stddev_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_stddev_samp_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_stddev_samp_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_stddev_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_sum_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_sum_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_sum_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_var_pop_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_var_pop_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_var_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_var_samp_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_var_samp_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_var_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29CleanJobTitle_variance_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29CleanJobTitle_variance_fieldsKeySpecifier)[];
export type sic29CleanJobTitle_variance_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29GeoKeySpecifier = ('count' | 'id' | 'name' | 'proportion' | 'unit' | 'year' | sic29GeoKeySpecifier)[];
export type sic29GeoFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	unit?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_aggregateKeySpecifier = ('aggregate' | 'nodes' | sic29Geo_aggregateKeySpecifier)[];
export type sic29Geo_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | sic29Geo_aggregate_fieldsKeySpecifier)[];
export type sic29Geo_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_avg_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_avg_fieldsKeySpecifier)[];
export type sic29Geo_avg_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_max_fieldsKeySpecifier = ('count' | 'id' | 'name' | 'proportion' | 'unit' | 'year' | sic29Geo_max_fieldsKeySpecifier)[];
export type sic29Geo_max_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	unit?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_min_fieldsKeySpecifier = ('count' | 'id' | 'name' | 'proportion' | 'unit' | 'year' | sic29Geo_min_fieldsKeySpecifier)[];
export type sic29Geo_min_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	unit?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_stddev_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_stddev_fieldsKeySpecifier)[];
export type sic29Geo_stddev_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_stddev_pop_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_stddev_pop_fieldsKeySpecifier)[];
export type sic29Geo_stddev_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_stddev_samp_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_stddev_samp_fieldsKeySpecifier)[];
export type sic29Geo_stddev_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_sum_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_sum_fieldsKeySpecifier)[];
export type sic29Geo_sum_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_var_pop_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_var_pop_fieldsKeySpecifier)[];
export type sic29Geo_var_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_var_samp_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_var_samp_fieldsKeySpecifier)[];
export type sic29Geo_var_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type sic29Geo_variance_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | sic29Geo_variance_fieldsKeySpecifier)[];
export type sic29Geo_variance_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type subscription_rootKeySpecifier = ('canonSkillsByYear' | 'canonSkillsByYear_aggregate' | 'distinctCanonSkill' | 'distinctCleanJobTitle' | 'rollingWindow30DaysCanonSkills' | 'rollingWindow30DaysCanonSkillsAggregate' | 'rollingWindow30DaysCleanJobTitle' | 'rollingWindow30DaysCleanJobTitleAggregate' | 'sic29CanonSkill' | 'sic29CanonSkillAggregate' | 'sic29CleanJobTitle' | 'sic29CleanJobTitleAggregate' | 'sic29Geo' | 'sic29GeoAggregate' | 'user' | 'users' | subscription_rootKeySpecifier)[];
export type subscription_rootFieldPolicy = {
	canonSkillsByYear?: FieldPolicy<any> | FieldReadFunction<any>,
	canonSkillsByYear_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	distinctCanonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	distinctCleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCanonSkills?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCanonSkillsAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	rollingWindow30DaysCleanJobTitleAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CanonSkill?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CanonSkillAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CleanJobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29CleanJobTitleAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29Geo?: FieldPolicy<any> | FieldReadFunction<any>,
	sic29GeoAggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type usersKeySpecifier = ('activated' | 'avatarUrl' | 'createdAt' | 'defaultRole' | 'disabled' | 'displayName' | 'email' | 'emailVerified' | 'id' | 'isAnonymous' | 'lastSeen' | 'locale' | 'metadata' | 'phoneNumber' | 'ticket' | 'updatedAt' | usersKeySpecifier)[];
export type usersFieldPolicy = {
	activated?: FieldPolicy<any> | FieldReadFunction<any>,
	avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	defaultRole?: FieldPolicy<any> | FieldReadFunction<any>,
	disabled?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	emailVerified?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isAnonymous?: FieldPolicy<any> | FieldReadFunction<any>,
	lastSeen?: FieldPolicy<any> | FieldReadFunction<any>,
	locale?: FieldPolicy<any> | FieldReadFunction<any>,
	metadata?: FieldPolicy<any> | FieldReadFunction<any>,
	phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	ticket?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	canonSkillsByYear?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYearKeySpecifier | (() => undefined | canonSkillsByYearKeySpecifier),
		fields?: canonSkillsByYearFieldPolicy,
	},
	canonSkillsByYear_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_aggregateKeySpecifier | (() => undefined | canonSkillsByYear_aggregateKeySpecifier),
		fields?: canonSkillsByYear_aggregateFieldPolicy,
	},
	canonSkillsByYear_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_aggregate_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_aggregate_fieldsKeySpecifier),
		fields?: canonSkillsByYear_aggregate_fieldsFieldPolicy,
	},
	canonSkillsByYear_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_avg_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_avg_fieldsKeySpecifier),
		fields?: canonSkillsByYear_avg_fieldsFieldPolicy,
	},
	canonSkillsByYear_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_max_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_max_fieldsKeySpecifier),
		fields?: canonSkillsByYear_max_fieldsFieldPolicy,
	},
	canonSkillsByYear_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_min_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_min_fieldsKeySpecifier),
		fields?: canonSkillsByYear_min_fieldsFieldPolicy,
	},
	canonSkillsByYear_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_stddev_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_stddev_fieldsKeySpecifier),
		fields?: canonSkillsByYear_stddev_fieldsFieldPolicy,
	},
	canonSkillsByYear_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_stddev_pop_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_stddev_pop_fieldsKeySpecifier),
		fields?: canonSkillsByYear_stddev_pop_fieldsFieldPolicy,
	},
	canonSkillsByYear_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_stddev_samp_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_stddev_samp_fieldsKeySpecifier),
		fields?: canonSkillsByYear_stddev_samp_fieldsFieldPolicy,
	},
	canonSkillsByYear_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_sum_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_sum_fieldsKeySpecifier),
		fields?: canonSkillsByYear_sum_fieldsFieldPolicy,
	},
	canonSkillsByYear_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_var_pop_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_var_pop_fieldsKeySpecifier),
		fields?: canonSkillsByYear_var_pop_fieldsFieldPolicy,
	},
	canonSkillsByYear_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_var_samp_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_var_samp_fieldsKeySpecifier),
		fields?: canonSkillsByYear_var_samp_fieldsFieldPolicy,
	},
	canonSkillsByYear_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | canonSkillsByYear_variance_fieldsKeySpecifier | (() => undefined | canonSkillsByYear_variance_fieldsKeySpecifier),
		fields?: canonSkillsByYear_variance_fieldsFieldPolicy,
	},
	distinctCanonSkill?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | distinctCanonSkillKeySpecifier | (() => undefined | distinctCanonSkillKeySpecifier),
		fields?: distinctCanonSkillFieldPolicy,
	},
	distinctCleanJobTitle?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | distinctCleanJobTitleKeySpecifier | (() => undefined | distinctCleanJobTitleKeySpecifier),
		fields?: distinctCleanJobTitleFieldPolicy,
	},
	query_root?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | query_rootKeySpecifier | (() => undefined | query_rootKeySpecifier),
		fields?: query_rootFieldPolicy,
	},
	rollingWindow30DaysCanonSkills?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkillsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkillsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkillsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_aggregateKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_aggregateKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_aggregateFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_aggregate_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_aggregate_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_aggregate_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_avg_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_avg_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_avg_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_max_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_max_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_max_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_min_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_min_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_min_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_stddev_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_stddev_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_stddev_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_stddev_pop_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_stddev_pop_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_stddev_pop_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_stddev_samp_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_stddev_samp_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_stddev_samp_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_sum_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_sum_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_sum_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_var_pop_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_var_pop_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_var_pop_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_var_samp_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_var_samp_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_var_samp_fieldsFieldPolicy,
	},
	rollingWindow30DaysCanonSkills_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCanonSkills_variance_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCanonSkills_variance_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCanonSkills_variance_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitleKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitleKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitleFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_aggregateKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_aggregateKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_aggregateFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_aggregate_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_aggregate_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_aggregate_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_avg_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_avg_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_avg_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_max_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_max_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_max_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_min_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_min_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_min_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_stddev_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_stddev_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_stddev_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_stddev_pop_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_stddev_pop_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_stddev_pop_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_stddev_samp_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_stddev_samp_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_stddev_samp_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_sum_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_sum_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_sum_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_var_pop_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_var_pop_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_var_pop_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_var_samp_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_var_samp_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_var_samp_fieldsFieldPolicy,
	},
	rollingWindow30DaysCleanJobTitle_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | rollingWindow30DaysCleanJobTitle_variance_fieldsKeySpecifier | (() => undefined | rollingWindow30DaysCleanJobTitle_variance_fieldsKeySpecifier),
		fields?: rollingWindow30DaysCleanJobTitle_variance_fieldsFieldPolicy,
	},
	sic29CanonSkill?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkillKeySpecifier | (() => undefined | sic29CanonSkillKeySpecifier),
		fields?: sic29CanonSkillFieldPolicy,
	},
	sic29CanonSkill_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_aggregateKeySpecifier | (() => undefined | sic29CanonSkill_aggregateKeySpecifier),
		fields?: sic29CanonSkill_aggregateFieldPolicy,
	},
	sic29CanonSkill_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_aggregate_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_aggregate_fieldsKeySpecifier),
		fields?: sic29CanonSkill_aggregate_fieldsFieldPolicy,
	},
	sic29CanonSkill_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_avg_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_avg_fieldsKeySpecifier),
		fields?: sic29CanonSkill_avg_fieldsFieldPolicy,
	},
	sic29CanonSkill_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_max_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_max_fieldsKeySpecifier),
		fields?: sic29CanonSkill_max_fieldsFieldPolicy,
	},
	sic29CanonSkill_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_min_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_min_fieldsKeySpecifier),
		fields?: sic29CanonSkill_min_fieldsFieldPolicy,
	},
	sic29CanonSkill_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_stddev_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_stddev_fieldsKeySpecifier),
		fields?: sic29CanonSkill_stddev_fieldsFieldPolicy,
	},
	sic29CanonSkill_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_stddev_pop_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_stddev_pop_fieldsKeySpecifier),
		fields?: sic29CanonSkill_stddev_pop_fieldsFieldPolicy,
	},
	sic29CanonSkill_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_stddev_samp_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_stddev_samp_fieldsKeySpecifier),
		fields?: sic29CanonSkill_stddev_samp_fieldsFieldPolicy,
	},
	sic29CanonSkill_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_sum_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_sum_fieldsKeySpecifier),
		fields?: sic29CanonSkill_sum_fieldsFieldPolicy,
	},
	sic29CanonSkill_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_var_pop_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_var_pop_fieldsKeySpecifier),
		fields?: sic29CanonSkill_var_pop_fieldsFieldPolicy,
	},
	sic29CanonSkill_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_var_samp_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_var_samp_fieldsKeySpecifier),
		fields?: sic29CanonSkill_var_samp_fieldsFieldPolicy,
	},
	sic29CanonSkill_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CanonSkill_variance_fieldsKeySpecifier | (() => undefined | sic29CanonSkill_variance_fieldsKeySpecifier),
		fields?: sic29CanonSkill_variance_fieldsFieldPolicy,
	},
	sic29CleanJobTitle?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitleKeySpecifier | (() => undefined | sic29CleanJobTitleKeySpecifier),
		fields?: sic29CleanJobTitleFieldPolicy,
	},
	sic29CleanJobTitle_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_aggregateKeySpecifier | (() => undefined | sic29CleanJobTitle_aggregateKeySpecifier),
		fields?: sic29CleanJobTitle_aggregateFieldPolicy,
	},
	sic29CleanJobTitle_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_aggregate_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_aggregate_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_aggregate_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_avg_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_avg_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_avg_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_max_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_max_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_max_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_min_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_min_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_min_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_stddev_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_stddev_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_stddev_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_stddev_pop_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_stddev_pop_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_stddev_pop_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_stddev_samp_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_stddev_samp_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_stddev_samp_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_sum_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_sum_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_sum_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_var_pop_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_var_pop_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_var_pop_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_var_samp_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_var_samp_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_var_samp_fieldsFieldPolicy,
	},
	sic29CleanJobTitle_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29CleanJobTitle_variance_fieldsKeySpecifier | (() => undefined | sic29CleanJobTitle_variance_fieldsKeySpecifier),
		fields?: sic29CleanJobTitle_variance_fieldsFieldPolicy,
	},
	sic29Geo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29GeoKeySpecifier | (() => undefined | sic29GeoKeySpecifier),
		fields?: sic29GeoFieldPolicy,
	},
	sic29Geo_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_aggregateKeySpecifier | (() => undefined | sic29Geo_aggregateKeySpecifier),
		fields?: sic29Geo_aggregateFieldPolicy,
	},
	sic29Geo_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_aggregate_fieldsKeySpecifier | (() => undefined | sic29Geo_aggregate_fieldsKeySpecifier),
		fields?: sic29Geo_aggregate_fieldsFieldPolicy,
	},
	sic29Geo_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_avg_fieldsKeySpecifier | (() => undefined | sic29Geo_avg_fieldsKeySpecifier),
		fields?: sic29Geo_avg_fieldsFieldPolicy,
	},
	sic29Geo_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_max_fieldsKeySpecifier | (() => undefined | sic29Geo_max_fieldsKeySpecifier),
		fields?: sic29Geo_max_fieldsFieldPolicy,
	},
	sic29Geo_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_min_fieldsKeySpecifier | (() => undefined | sic29Geo_min_fieldsKeySpecifier),
		fields?: sic29Geo_min_fieldsFieldPolicy,
	},
	sic29Geo_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_stddev_fieldsKeySpecifier | (() => undefined | sic29Geo_stddev_fieldsKeySpecifier),
		fields?: sic29Geo_stddev_fieldsFieldPolicy,
	},
	sic29Geo_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_stddev_pop_fieldsKeySpecifier | (() => undefined | sic29Geo_stddev_pop_fieldsKeySpecifier),
		fields?: sic29Geo_stddev_pop_fieldsFieldPolicy,
	},
	sic29Geo_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_stddev_samp_fieldsKeySpecifier | (() => undefined | sic29Geo_stddev_samp_fieldsKeySpecifier),
		fields?: sic29Geo_stddev_samp_fieldsFieldPolicy,
	},
	sic29Geo_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_sum_fieldsKeySpecifier | (() => undefined | sic29Geo_sum_fieldsKeySpecifier),
		fields?: sic29Geo_sum_fieldsFieldPolicy,
	},
	sic29Geo_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_var_pop_fieldsKeySpecifier | (() => undefined | sic29Geo_var_pop_fieldsKeySpecifier),
		fields?: sic29Geo_var_pop_fieldsFieldPolicy,
	},
	sic29Geo_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_var_samp_fieldsKeySpecifier | (() => undefined | sic29Geo_var_samp_fieldsKeySpecifier),
		fields?: sic29Geo_var_samp_fieldsFieldPolicy,
	},
	sic29Geo_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | sic29Geo_variance_fieldsKeySpecifier | (() => undefined | sic29Geo_variance_fieldsKeySpecifier),
		fields?: sic29Geo_variance_fieldsFieldPolicy,
	},
	subscription_root?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | subscription_rootKeySpecifier | (() => undefined | subscription_rootKeySpecifier),
		fields?: subscription_rootFieldPolicy,
	},
	users?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | usersKeySpecifier | (() => undefined | usersKeySpecifier),
		fields?: usersFieldPolicy,
	}
};

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    