import { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useRecoilState } from 'recoil';
import { atoms, useLayout, useWindowDimensions } from 'misc';
import { LngLatBoundsLike, Map } from 'mapbox-gl';
import { useLocation } from 'react-router-dom';
import { useGeoDataQuery } from 'generated';


export default function DynamicStyling ({map}:{map:Map}) {

  const [geography, setGeographies] = useRecoilState(atoms.geography);
  const [date, setDate] = useRecoilState(atoms.date);
  const [year, setYear] = useState(date?.getFullYear()||2022);

  const [countRange, setCountRange] = useRecoilState(atoms.countRange);
  const [_, setData] = useRecoilState(atoms.data);

  useEffect(()=>{
    if(year!==date?.getFullYear()){
      setYear(date?.getFullYear()||2022)
    }
  },[date, year])

  const {data} = useGeoDataQuery({variables:{
    year, 
    unit:
      geography==='Local Authority Districts'?
        'lad':
      geography==='England Regions'?
        'region':
        'country'
  }})

  useEffect(()=>{

    if(map && data){
      setData(data.sic29Geo)
      setCountRange([data.sic29GeoAggregate.aggregate?.min?.count||0, data.sic29GeoAggregate.aggregate?.max?.count||1])
      const max = data.sic29Geo.reduce((acc,curr)=>Math.max(acc,curr.proportion),0)
      const min = data.sic29Geo.reduce((acc,curr)=>Math.min(acc,curr.proportion),0)
      data.sic29Geo.forEach(v=>{
        if(v.id)
        map.setFeatureState(
          {
            source: 'admin',
            sourceLayer: v.unit==='lad'?'lads':v.unit==='region'?'regions':'countries',
            id: v.id,
          },
          {
            count: v.count,
            proportion: (v.proportion - min)/(max-min),
          }
        );
      })
    }
  },[map, data])
  
  return null
}
