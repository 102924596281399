import { Container, Wrapper } from './styles';
import { atoms, useWindowDimensions } from 'misc';
import Header from './Header';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useRecoilState } from 'recoil';
import Filters from './Filters';
import Sliders from './Sliders';
import RollingBars from './RollingBars';
import YearlyBars from './YearlyBars';
import LineChartSkill from './LineChartSkill';

export default function Sidebar() {
  const { height } = useWindowDimensions();
  const [selected] = useRecoilState(atoms.selected);
  const [date] = useRecoilState(atoms.date);
  const { zoom, ...coords } = atoms.defaultViewport;
  const [timePeriod, setTimePeriod] = useRecoilState(atoms.timePeriod);

  return (
    <>
      <Wrapper state={'dashboard'}>
        <Header />
          <SimpleBar
            // autoHide={true}
            style={{ boxSizing: 'border-box', maxHeight: height - 160 }}
          >
            <Container>
              <Filters/>
              <Sliders/>
              {timePeriod==='Last 30 days' && <RollingBars/>}
              {timePeriod==='Year-to-date' && <YearlyBars/>}
              {timePeriod==='Full historical data' && <LineChartSkill/>}

            </Container>
          </SimpleBar>
      </Wrapper>
    </>
  );
}
