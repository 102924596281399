import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Maybe, useCanonSkillsQuery } from '../../../generated/graphql'
import { SyntheticEvent, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { AI_SEARCH_TYPE } from './AiSearchType';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';

export default function SearchField() {
  const { data, loading, error } = useCanonSkillsQuery()
  const [option, setOption] = useState<string|null>(null)
  const [searchData, setSearchData] = useRecoilState(atoms.searchData);

  useEffect(()=>{
    (async ()=>{
      if(option){
        try{
          const res = await fetch(
            'https://search.automotive-skills-ecosystem.com/retriever',
            {
              body: JSON.stringify({
                "jobadvert": decodeURIComponent(option),
                "loc": decodeURIComponent(option),
              }),
              method: 'POST'
            }
          );
          const data = JSON.parse((await res.json())?.data||'[]') as AI_SEARCH_TYPE[]
          setSearchData(data)
        }catch(err){
          setSearchData([])
        }
      }

    })()
  },[option])

  return (
    data ? <Autocomplete
      id="canon-skill"
      size='small'
      fullWidth
      value={{label: option||''}}
      onChange={(event: SyntheticEvent<Element, Event>, newValue: {label: Maybe<string> | undefined;}|null) => {
        setOption(newValue?.label || null)
      }}
      options={data?.distinctCanonSkill?.filter(v=>!!v?.canonSkill)?.map(v=>({label: v?.canonSkill}))}
      renderInput={(params) => <TextField {...params} label="Select Skill or Job" />}
    />: null
  );
}

