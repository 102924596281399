import { useApolloClient } from "@apollo/client";
import { atoms } from "misc";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useUserLazyQuery } from 'generated'
// import * as FullStory from '@fullstory/browser';
import { useNhostClient } from '@nhost/react';

export default function useAuthSetup(){
  const { auth } = useNhostClient();
  const [, setUser] = useRecoilState(atoms.user);
  const [getUser, {data}] = useUserLazyQuery({ fetchPolicy: 'no-cache' });
  const client = useApolloClient();
  const router = useHistory();

  useEffect(() => {
    if (data && auth.isAuthenticated()) {
      setUser(data?.user);
      router.push('/')
    }
  }, [data])

  useEffect(() => {
    auth.onAuthStateChanged(async (event, session) => {
      if (event==='SIGNED_IN') {

        const user = auth.getUser();
        const status = await getUser({ variables: { user_id: user?.id } });
        setUser(status.data?.user);
        router.push('/')
        // FullStory.identify(u?.id, {
        //   email: u?.account?.email,
        //   department: u?.department,
        //   first_name: u?.first_name,
        //   last_name: u?.last_name,
        //   mobile: u?.mobile,
        //   organisation: u?.organisation,
        //   role: u?.role,
        // });
      } else {
        setUser(null);
        client.resetStore();
      }
    });
  }, []);
  return {}
}