import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ state: 'start' | 'info' | 'dashboard' }>`
    left: 1rem;
    background-color: white;
    /* width: 50vw; */
    min-width: ${(props) =>
      props.state === 'start' || props.state === 'dashboard'
        ? css<{}>`400px`
        : css<{}>`600px`};
    max-height: ${(props) =>
      props.state === 'start'
        ? css<{}>`min(600px, calc(100vh - 2.2rem))`
        : css<{}>`calc(100vh - 2.2rem)`};
    /* max-height: fit-content; */
    max-width: 300px;
    box-shadow: inset 0px 4px 0px #ffe964, 0px 4px 4px rgb(0 0 0 / 25%),
      0px 16px 16px rgb(0 0 0 / 6%);
    box-sizing: border-box;
    position: relative;
    margin-top: 1rem;
    z-index: 15;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    transition: height 0.3s ease-in-out, min-width 0.3s ease-in-out,
    max-height 0.3s ease-in-out;
  `,

  
  HeaderIcon = styled.div`
    position: absolute;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    width: calc(100% - 2rem);
    z-index: 100;
    height: 115px;
    display: flex;
    align-items: center;  

  `,

  HeaderIconRelative = styled.div`
    position: relative;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 115px;
    display: flex;
    align-items: center; 
  `,

  Container = styled.div`
    padding: 1rem;
    min-height: 100%;
    height: 100%;
  `