import { useRecoilState } from 'recoil';
import { Cell, Image, Info, PanelWrapper } from './styles'
import { atoms } from 'misc';
import Poly2 from 'assets/images/poly2.jpg';
import Poly1 from 'assets/images/poly1.png';

import Logout from 'assets/images/logout.png';
import { useNhostClient } from '@nhost/react';
import { useHistory } from 'react-router-dom';

export default function TopPanel(){

  const [view, setView] = useRecoilState(atoms.view);
  const { auth } = useNhostClient()
  const router = useHistory()
  return <>
    
    <PanelWrapper style={{flex: '1 1 auto'}}>
      <>
        <Cell>
          <Image 
            title="Relative Employer Skill Demand"
            selected={view === 'absolute'}
            src={Poly1}
            onClick={()=>{setView('absolute');}}
          />
          <Info 
            title="Relative Employer Skill Demand" 
            style={{cursor: 'pointer'}} 
            onClick={()=>{setView('absolute');}}>
              Relative Employer <br/> Skill Demand
          </Info>
        </Cell>
        <Cell>
          <Image 
            title="Absolute Employer Skill Demand"
            selected={view === 'relative'}
            src={Poly2}
            onClick={()=>{setView('relative');}}
          />
          <Info 
            title="Absolute Employer Skill Demand"
            style={{cursor: 'pointer'}} 
            onClick={()=>{setView('relative');}}>
              Absolute Employer <br/> Skill Demand
          </Info>
        </Cell>
        <Cell style={{marginLeft:'auto'}}>
          <Image 
            title="Logout"
            selected={false}
            src={Logout}
            onClick={()=>{auth.signOut().then(()=>{router.push("/authentication/login")})}}
          />
          <Info 
            title="Logout" 
            style={{cursor: 'pointer'}} 
            onClick={()=>{auth.signOut().then(()=>{router.push("/authentication/login")})}}>
              Logout
          </Info>

        </Cell>
      </>
    </PanelWrapper>
  </>
}