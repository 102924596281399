export function random(seed: number) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export const toUrlEncoded = (obj: any) =>
  Object.keys(obj)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&');

export function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMonth(date: string, months: number) {
  const result = parseISOLocal(date);
  result.setMonth(result.getUTCMonth() + months);
  const month = result.getUTCMonth();
  return `${result.getFullYear()}-${month < 10 ? '0' + month : month}`;
}

export function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

export function describeArc(
  x: number = 0,
  y: number = 0,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);
  var arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
  ].join(' ');
  return d;
}

export function datediff(first: Date, second: Date) {
  return Math.round(((second as any) - (first as any)) / (1000 * 60 * 60 * 24));
}

export function monthdiff(first: string, second: string) {
  const firstDate = new Date(Date.parse(first));
  const secondDate = new Date(Date.parse(second));
  return secondDate.getMonth() - firstDate.getMonth();
}

// Date minus one day
export function yesterday(date: Date | undefined) {
  return date
    ? new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    : undefined;
}

export function last_month(date: string | undefined) {
  if (date) {
    const dateObject = new Date(Date.parse(date));
    const year = dateObject.getFullYear();
    const month = dateObject.getUTCMonth() - 1;
    return `${year}-${month < 10 ? '0' + (month + 1) : month + 1}`;
  }
}

export function getDateString(date?: Date | null) {
  return date?.toLocaleDateString('en-GB').split('/').reverse().join('-');
}

export function parseISOLocal(s?:string) {
  if(s){
    let ds = String(s).split(/\D/).map(s => parseInt(s));
    ds[1] = ds[1] - 1; // adjust month
    // @ts-ignore
    return new Date(...ds);
  
  }else{
    return new Date();
  }
}
