import { 
  ChartDataShape,
  LinearAxisTickLabel,
  LinearXAxis,
  LinearXAxisTickLabel,
  LinearXAxisTickSeries,
  LinearYAxis,
  LinearYAxisTickSeries,
  LineChart,
  LineSeries,
} from 'reaviz';
import { useStatsByYearQuery } from 'generated';
import { useEffect, useState } from 'react';
import { interpolateViridis as color } from 'd3-scale-chromatic'
import { Typography } from '@mui/material';
import { schemaColors } from 'misc/recoil/atoms';

type Data = {
  key: number|string,
  data: number|Data
}[]|undefined

export default function BarChart ({noText=false, label=false}) {

  const [data, setData] = useState<ChartDataShape[]>()
  const {data:raw} = useStatsByYearQuery()

  useEffect(()=>{
    if(raw){
      const flat = raw.canonSkillsByYear.flatMap(v=>v.canonSkills)
      const skills = Array.from(new Set(flat.map(v=>v.name))).filter(n=>flat.filter(c=>c.name===n).length>=7)
      const d = skills.map(skill=>{
        const data = raw.canonSkillsByYear.map(v=>{
          const skillData = v.canonSkills.find(v2=>v2.name===skill)
          return {
            key: String(skillData?.year||0),
            data: skillData?.count || 0
          }
        })

        return {
          key: skill||'',
          data
        }
      })
      setData(d)
    }
  },[raw])

  return <div style={{marginTop:'1.5rem'}}>
    <Typography mb='1rem' color={'grey'}>Top Skills through the years:</Typography>
    <LineChart
      width={368}
      height={300}
      xAxis={
        <LinearXAxis
          type="value"
          domain={[2016,2022]}
        />
      }
      yAxis={
        <LinearYAxis
          type='value'
          roundDomains
        />
      }
      data={ data }
      series={
        <LineSeries
          type='grouped'
          colorScheme={
            (_data, _index, active) => 
              schemaColors?.[_index]?
              schemaColors?.[_index]:
              color(0.8-(_index/10)) }
        />
      }
    />
  </div>
}