import { atom } from 'recoil';
import { GeoDataQuery, UserQuery } from 'generated';
import { AI_SEARCH_TYPE } from 'components/Sidebar/Filters/AiSearchType';

export type Geographies = "UK Countries"|"England Regions"|"Local Authority Districts"
export type TimePeriods = "Last 30 days"|"Year-to-date"|"Full historical data"

export const geography = atom({
  key: 'geography',
  default: 'UK Countries' as Geographies,
});

export const timePeriod = atom({
  key: 'timePeriod',
  default: 'Last 30 days' as TimePeriods,
});

export const timeScale = atom({
  key: 'timeScale',
  default: 'day' as 'day' | 'month',
});

export const cursor = atom({
  key: 'cursor',
  default: '',
});

export const data = atom({
  key: 'data',
  default: null as GeoDataQuery['sic29Geo']|null,
});

export const selected = atom({
  key: 'selected',
  default: null as {id:string,name:string} | null,
});

export const defaultViewport = {
  latitude: 51.4619,
  longitude: -0.0987,
  zoom: 11.65,
};

export const viewport = atom({
  key: 'viewport',
  default: { latitude: 51.4631, longitude: -0.1428, zoom: 6 },
});

export const searchData = atom({
  key: 'searchData',
  default: [] as AI_SEARCH_TYPE[],
});

export const view = atom({
  key: 'view',
  default: 'absolute' as 'absolute' | 'relative',
});


export const date = atom({
  key: 'date',
  default: undefined as Date | undefined,
});

export const countRange = atom({
  key: 'countRange',
  default: [0,1]
});

export const month = atom({
  key: 'month',
  default: '2021-07' as string | undefined,
});


export const schemaColors = [
  '#A5B4FC',
  '#818CF8',
  '#6366F1',
  '#4F46E5',
  '#4338CA',
  '#3730A3',
  '#312E81'  
]


export const user = atom({
  key: 'user',
  default: null as UserQuery['user']
});
