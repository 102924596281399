import { Source } from '@urbica/react-map-gl';
import { atoms } from 'misc';
import { useRecoilState } from 'recoil';

export default function Sources(){
  const [searchData, setSearchData] = useRecoilState(atoms.searchData);

  return <>
      <Source
        id="admin"
        promoteId="id"
        type="vector"
        tiles={["https://dm7xac1rxtbi.cloudfront.net/uk-administrative/{z}/{x}/{y}.pbf"]}
        maxzoom={11}
      />
      <Source
        id="clip"
        type="vector"
        tiles={["https://dm7xac1rxtbi.cloudfront.net/clip/{z}/{x}/{y}.pbf"]}
        maxzoom={11}
      />
    <Source 
      id='entries-clusters'
      type='geojson'
      promoteId='id'
      data={{type: 'FeatureCollection', features: searchData
        ?.filter(v=>v.Longitude&&v.Latitude)
        ?.map(v=>({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [Number(v.Longitude), Number(v.Latitude)]
          },
          properties: {
            ...v
          }
        }) as GeoJSON.Feature)}}
      cluster={true}
      clusterMaxZoom={11}
      clusterRadius={50}
    />
  </>
}