import {
  ButtonRow,
  Header,
  Wrapper,
  Button,
  TextInput,
  InputRow,
  AuthWrapper, LinkRow
} from '../styles';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components'
import { Snackbar } from '@mui/material';
import { useNhostClient } from '@nhost/react';

const Uppercase = styled.div`
  *>:first-letter {text-transform: uppercase}
`

export default function Forgot() {
  const { auth } = useNhostClient();
  const [credentials, setCredentials] = useState({ email: '' });
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Forgot password?</Header>
          <InputRow>
            <TextInput
              required
              value={credentials.email}
              onChange={(e: any) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              id="email"
              placeholder="Email address *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              onClick={() =>{
                  if(credentials.email){
                    auth
                    .changePassword({newPassword: credentials.email})
                    .then(() => {
                      history.push('/authentication/reset-link-sent');
                    })
                    .catch((err) => {
                      setError(err?.response?.data?.message?.replaceAll('"', '') || err.message)
                    })
                  }else{
                    setError('Please, enter your email')
                  }
                }
              }
            >
              Reset password
            </Button>
          </ButtonRow>
          <LinkRow>
            <div />
            <Link to="/authentication/login">Back to login</Link>
          </LinkRow>
        </Wrapper>
      </AuthWrapper>
      <Uppercase>
        <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
          open={!!error}
          onClose={()=>setError(null)}
          message={error}
        />
      </Uppercase>
    </>
  );
}
