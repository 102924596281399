import { AuthWrapper, Header, Wrapper } from '../styles';

export default function ResetLink() {
  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Reset link sent</Header>
          <div>Check your inbox and click the link to reset password.</div>
        </Wrapper>
      </AuthWrapper>
    </>
  );
}
