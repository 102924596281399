import { atoms } from "misc";
import { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";
import { useRecoilState } from "recoil";


export const AnonimousRoute = ({
  children,
  ...rest
}: {
  children: ReactNode;
  path: string;
  exact?: boolean;
}) => {
  const [user] = useRecoilState(atoms.user);

  return !user?.id ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Route {...rest}>
      <Redirect to="/" />
    </Route>
  );
};