import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface Size {
  width: number;
  height: number;
}

interface Ref {
  ref: RefObject<HTMLDivElement>;
}

// Hook to get the size of a ref and to update it when the ref changes
export const useSize = (): Size & Ref => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const updateSize = useCallback(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  useLayoutEffect(() => {
    updateSize();
  }, [updateSize]);

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        updateSize();
      });
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref, updateSize]);

  return { ref, width, height };
};

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): Size &
  Ref {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const ref = useRef(null) as RefObject<HTMLDivElement>;

  // Prevent too many rendering using useCallback
  const updateSize = useCallback(() => {
    const node = ref?.current;
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      });
    }
  }, [ref]);

  // Initial size on mount
  useEffect(() => {
    updateSize();
    setTimeout(() => updateSize(), 100);
  }, []);

  return { ref, ...size };
}

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({
    x: null as null | number,
    y: null as null | number,
  });

  const updateMousePosition = (ev: any) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
};

export function useLayout(){
  const { width, height } = useWindowDimensions()
  return (width<768)? 'mobile': 'desktop'
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    // const { screen: { width, height}, devicePixelRatio } = window;
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function usePrevious<T>(value: T, options?:{ notNull?:boolean, unique?: boolean }) {
  const ref = useRef<T>();
  useEffect(() => {
    if(options?.notNull){
      if(value!==null){
        ref.current = value;
      }
    }else if(options?.unique){
      if(JSON.stringify(ref.current)!==JSON.stringify(value)){
        ref.current = value;
      }
    }else{
      ref.current = value;
    }
  });
  return ref.current;
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}