import styled from 'styled-components'


export const
PanelWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: calc(120px + 2rem);
  right: 1rem;
  bottom: 1.2rem;
  background-color: white;
  width: 360px;
  padding-top: 5px;
  box-shadow: inset 0px 4px 0px #ffe964, 0px 4px 4px rgb(0 0 0 / 25%),
      0px 16px 16px rgb(0 0 0 / 6%);
  .simplebar-content{
    min-height: 100%;
    display: flex;
  }
`,

Card = styled.div`
  background-color: #EEF2FF;
  border-radius: 1rem;
  padding: 0 2rem;
  padding-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  span,a{
    color: rgba(0,0,0,0.8);
    white-space: nowrap;
    font-size: 0.8rem;

  }
`