import { AuthWrapper, Header, Wrapper } from '../styles';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNhostClient } from '@nhost/react';

export default function Verified() {
  const { auth } = useNhostClient();
  const { search } = useLocation();
  let ticket = new URLSearchParams(search).get('ticket');

  useEffect(() => {
    if (ticket) {
      auth
        .refreshSession(ticket)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ticket]);

  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Verified</Header>
          <div style={{textAlign:'center'}}>
            Your email has been verified and currently your account under review. You will
            receive an email within next 24 hours when your account has been reviewed.
          </div>
        </Wrapper>
      </AuthWrapper>
    </>
  );
}
