import { atoms, useSize } from 'misc';
import { useRecoilState } from 'recoil';
import { Card, PanelWrapper } from './styles'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import distance from '@turf/distance';
import { AI_SEARCH_TYPE } from 'components/Sidebar/Filters/AiSearchType';

const allowedKeys = [
  'AvgAnnualSalary',
  'CanonCity',
  'CanonEmployer',
  'CanonJobTitle',
  'CleanJobTitle',
  'Email',
  'JobDate',
  'JobDomain',
  'JobURL',
  'SICCode'
]

export default function RightPanel(){
  const { ref, height } = useSize();
  const [searchData, setSearchData] = useRecoilState(atoms.searchData);
  const [viewport, setViewport] = useRecoilState(atoms.viewport);

  return <PanelWrapper {...{ref}}>
    <SimpleBar
      autoHide={true}
      style={{ boxSizing: 'border-box', padding:'15px', maxHeight: height - 10, height: height - 10 }}
    >
        <div style={{minHeight: height - 40, width: '100%', gap:'1rem 1rem', display:'flex', flexDirection:'column', whiteSpace:'pre-wrap'}}>
          <h3>Search results: </h3>
          {searchData.length===0 && <h5 style={{marginTop:'-1rem', color:'rgba(0,0,0,0.5)'}}>Select skill or job in the left panel...</h5>}
          {[...searchData]
            .filter((v)=>v?.Longitude&&v?.Latitude)
            ?.sort((a,b)=>{
              const aDist = distance([Number(a.Longitude), Number(a.Latitude)], [viewport.longitude, viewport.latitude]);
              const bDist = distance([Number(b.Longitude), Number(b.Latitude)], [viewport.longitude, viewport.latitude]);
              return aDist - bDist;
            })
            ?.map((v,i)=><CardContainer key={i} data={v} {...{viewport}}/>)}
          {[...searchData]
            .filter((v)=>!v?.Longitude||!v?.Latitude)
            ?.map((v,i)=><CardContainer key={i} data={v} {...{viewport}}/>)}
        </div>
      </SimpleBar>
  </PanelWrapper>
}


function CardContainer({data:v, viewport}:{data:AI_SEARCH_TYPE, viewport:{longitude:number, latitude:number}}){

  return <Card>
  <h5>{v.CanonJobTitle||v.CleanJobTitle}</h5>
  {Object.entries(v)
    .filter(([k,v])=>k!=='CanonJobTitle' && k!=='CleanJobTitle' && allowedKeys.includes(k) && !!v)
    .map(([k,d])=>
      (k==='JobDomain'||k==='JobURL'||k==='Email')?
      <><span key={k}><b>{k}:</b> <a href={k==='Email'?`mailto:${d}`:d?.includes('http')?d:`https://${d}`} target='_blank' rel='noreferrer'>{d}</a></span><br/></>:  
      <><span key={k}><b>{k}:</b> {d}</span><br/></>)}
      {/* <><span><b>Distance:</b> {distance([Number(v.Longitude), Number(v.Latitude)], [viewport.longitude, viewport.latitude])}</span><br/></>
      <><span><b>LatLon:</b> {v.Longitude} {v.Latitude}</span><br/></> */}
</Card>
}