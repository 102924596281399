import {
  ButtonRow,
  Header,
  Wrapper,
  Button,
  TextInput,
  InputRow,
  AuthWrapper, LinkRow
} from '../styles';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Snackbar } from '@mui/material'
import styled from 'styled-components'
import { useNhostClient } from '@nhost/react';

const Uppercase = styled.div`
  *>:first-letter {text-transform: uppercase}
`

export default function Register() {
  const { auth } = useNhostClient();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    options: {
      userData: {
        first_name: '',
        last_name: '',
        organisation: '',
        department: '',
        role: '',
      },
    },
  });
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  const disabled = !credentials.email || 
  !credentials.password ||
  !credentials.options.userData.first_name ||
  !credentials.options.userData.last_name ||
  !credentials.options.userData.organisation ||
  !credentials.options.userData.department ||
  !credentials.options.userData.role

  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Register</Header>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.first_name}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      first_name: e.target.value,
                    },
                  },
                })
              }
              id="Name"
              placeholder="Name *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.last_name}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      last_name: e.target.value,
                    },
                  },
                })
              }
              id="Surname"
              placeholder="Surname *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.organisation}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      organisation: e.target.value,
                    },
                  },
                })
              }
              id="Organisation"
              placeholder="Organisation *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.department}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      department: e.target.value,
                    },
                  },
                })
              }
              id="Unit / Department"
              placeholder="Unit / Department *"
            />
            <TextInput
              required
              value={credentials.options.userData.role}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      role: e.target.value,
                    },
                  },
                })
              }
              id="Role"
              placeholder="Role *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.email}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              id="Email"
              placeholder="Email *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              type="password"
              id="Password"
              placeholder="Password *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              onClick={() => {
                if(!disabled){
                  auth
                    .signUp({
                      email: credentials.email, 
                      password: credentials.password, 
                      options:{ metadata: credentials.options.userData, redirectTo: window.location.href }})
                    .then((response) => {
                      // 
                      if (!response.error) {
                        history.push('/authentication/email-verify');
                      }else{
                        setError(response?.error?.message)
                      }
                    })
                    .catch((err) => {
                      setError(err?.response?.data?.message?.replaceAll('"', '') || err.message)
                    })
                }else{
                  setError('Please fill in all mandatory fields')
                }
              }
              }
            >
              Register
            </Button>
          </ButtonRow>
          <LinkRow>
            <div />
            <Link to="/authentication/login">Login</Link>
          </LinkRow>
        </Wrapper>
      </AuthWrapper>
      <Uppercase>
        <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
          open={!!error}
          onClose={()=>setError(null)}
          message={error}
        />
      </Uppercase>
    </>
  );
}

