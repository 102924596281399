import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { NhostClient } from '@nhost/react'
import { NhostApolloProvider } from '@nhost/react-apollo'
import App from './components/App';
import {  ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { InMemoryCache } from '@apollo/client';
import { theme, cacheConfig } from './misc';
import { ThemeProvider as StyledTheme } from 'styled-components';
import { NhostAuthProvider } from '@nhost/react-auth';
import * as FullStory from '@fullstory/browser';
import SearchField from './components/Sidebar/Filters/SearchField'
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax 
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <ThemeProvider theme={theme}>
          <StyledTheme theme={theme}>
            <NhostApolloProvider
              {...{ nhost }}
              cache={new InMemoryCache({
                addTypename: false,
                ...cacheConfig
              })}
              publicRole="public"
            >
              <NhostAuthProvider {...{ nhost }}>
                <App />
              </NhostAuthProvider>
            </NhostApolloProvider>
          </StyledTheme>
        </ThemeProvider>
      </Router>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
