import Sidebar from './Sidebar';
import Map from './Map';
import { AnonimousRoute, GlobalStyle, ProtectedRoute, ApprovedProtectedRoute } from 'misc';
import { AppWrapper } from './styles';
import { Switch, Route } from 'react-router-dom';
import EmailVerify from 'components/Auth/Authentication/EmailVerify';
import Login from 'components/Auth/Authentication/Login';
import Register from 'components/Auth/Authentication/Register';
import PasswordForgot from 'components/Auth/Password/Forgot';
import PasswordNew from 'components/Auth/Password/New';
import ResetLink from 'components/Auth/Password/ResetLink';
import AccountVerified from 'components/Auth/Account/Verified';
import AccountApprove from 'components/Auth/Account/Approve';
import AccountReviewed from 'components/Auth/Account/Reviewed';
import useAuthSetup from 'misc/nhost/useAuthSetup';
import TopPanel from './TopPanel';
import RightPanel from './RightPanel';

export default function App() {
  useAuthSetup();

  return (
    <AppWrapper>
      <GlobalStyle />
      <Switch>
        <ApprovedProtectedRoute exact path="/">
          <Map />
          <Sidebar />
          <TopPanel/>
          <RightPanel/>
        </ApprovedProtectedRoute>
        <ProtectedRoute exact path="/authentication/reviewed">
          <AccountReviewed/>
        </ProtectedRoute>
        <AnonimousRoute path="/authentication/email-verify">
          <EmailVerify />
        </AnonimousRoute>
        <AnonimousRoute path="/authentication/login">
          <Login />
        </AnonimousRoute>
        <AnonimousRoute path="/authentication/register">
          <Register />
        </AnonimousRoute>
        <AnonimousRoute path="/authentication/forgot-password">
          <PasswordForgot />
        </AnonimousRoute>
        <AnonimousRoute path="/authentication/change-password">
          <PasswordNew />
        </AnonimousRoute>
        <AnonimousRoute path="/authentication/reset-link">
          <ResetLink />
        </AnonimousRoute>
        <Route path="/authentication/verified">
          <AccountVerified />
        </Route>
        
        <Route path="/admin/approve">
          <AccountApprove />
        </Route>
      </Switch>
    </AppWrapper>
  );
}
