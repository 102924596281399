import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body{
    margin: 0;
    padding: 0;
  }
  html * {
    font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
  }
  html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .custom-popup {
    opacity: 0.95;
    font: 10px Helvetica;
    max-width: fit-content;
    max-height: none;
  }
  .mapboxgl-popup-content {
    overflow: hidden;
    z-index: 2;
    display: 'block';
    background-color: #eeeeee;
    opacity: 0.85;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.2);
    text-align: left;
    padding: 1rem;
    /* border-radius: 0.5rem; */
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .MuiSwitch-track {
    background-color: #ccc;
  }
  .mapboxgl-ctrl-bottom-right{
    bottom: 0.6rem !important;
    right: calc(360px + 0.9rem) !important;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-group{
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 16px 16px rgb(0 0 0 / 6%) !important;
  }
  a{
    color: black;
  }
`;
