import styled, { css } from 'styled-components/macro';
import MapGLUnstyled from '@urbica/react-map-gl';

export const MapGL = styled(MapGLUnstyled)<{path?:string}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  outline: none;
  z-index: 0;
`;
