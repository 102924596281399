import { useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useRecoilState } from 'recoil';
import { atoms, useLayout, useWindowDimensions } from 'misc';
import { LngLatBoundsLike, Map } from 'mapbox-gl';
import { useLocation } from 'react-router-dom';


export default function FitView ({map}:{map:Map}) {
  const {width,height} = useWindowDimensions()
  const layout = useLayout()
  const [view, setView] = useRecoilState(atoms.view)
  const path = useLocation().pathname;

  useEffect(()=>{
    const country:LngLatBoundsLike = [
      [0.5, 50],
      [3.5, 60]
    ]//50.87/-8.78
    //62.27/-0.6

    if(map){
      const offset = 60;
      map.setMaxBounds([[-35.57216793459, 44.959999905], [30.68153079591, 66.6350001085]])
      map.fitBounds(country, {
        padding: {
          top: path.includes('social-isolation')? offset :145 + offset,
          bottom: offset,
          left: 400 + offset,
          right: path.includes('air-pollution-monitor')? 360 + offset: offset
        }
      })
    }
  },[map, width, height, view])
  
  return null
}
