import { scaleLinear } from 'd3-scale';
import styled, { css, keyframes } from 'styled-components/macro';
import { ReactComponent as TrendIcon } from 'assets/images/icon_trend.svg';
import { format } from 'd3-format';
import { Maybe } from 'generated';
import { schemaColors } from 'misc/recoil/atoms';

export type Ranked = {
  name: Maybe<string> | undefined;
  count: Maybe<number> | undefined;
  index: number;
  prevIndex: number;
}[];


const formatter = format('.2f');


const colorScale = scaleLinear()
  .domain(schemaColors.map((v, i, a) => i / a.length))
  .range([...schemaColors].reverse() as any);


export const 
Button = styled.button`
  width:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  background-color:white;
  transition: background-color 0.2s ease-in-out;
  &:hover{
    background-color: #f0f0f0;
  }
  :first-child{
    margin-right: 15px;
  }
  :last-child{
    margin-left: 15px;
  }
`,

SliderContainer = styled.div`
  margin-top:1rem;
  display:flex;
  align-items:center;
  .MuiSlider-valueLabel{
    min-width: 50px !important;
    font-size: 8px !important;
    color: black;
    p{
      width: 10px;
      line-height: 0px;
    }
  }
`,

Header = styled.h6`
    font-size: 12;
    line-height: 1.2;
    font-weight: 500;
    margin: 0 0 1rem 0;
    margin-top: 2rem;
  `,

Rank = styled.div.attrs(
  (props: {
    items: Ranked;
    previous?: Ranked;
    itemHeight: number;
    length: number;
  }) => {

    return {
      previous: props.previous,
      length: props.length,
      children: (
        <>
          {props.items?.map((r, key, a) => (
            <Row
              key={r?.name}
              index={key}
              items={a}
              previous={props.previous!}
              height={props.itemHeight}
              count={a.length}
              title={r?.name||''}
            >
              <span>
                <div style={{textOverflow:'ellipsis', maxWidth:'110px', width: '110px', overflow:'hidden', whiteSpace:'nowrap'}}>
                  <span style={{width: 'fit-content'}}>{r?.name}</span>
                </div>
                <div>
                  <TrendIcon />
                </div>
                <div>
                  {typeof r?.index === 'number' && r?.prevIndex !== r?.index
                    ? Math.abs(r?.prevIndex - r?.index)
                    : ''}
                </div>
              </span>
              <span>
                <div>
                  <span>{r?.count}</span>
                </div>
              </span>
            </Row>
          ))}
        </>
      ),
    };
  }
)<{ items: Ranked; itemHeight: number; length: number }>`
  height: ${(props) => props.length * props.itemHeight}px;
  width: 100%;
  position: relative;
  overflow: hidden;
`,
enterAnimation = (v: number, total: number) => keyframes`
0% { top: ${total}px; }
100% { top: ${v}px; }
`,

Row = styled.div<{
  index: number;
  height: number;
  count: number;
  items: Ranked;
  previous: Ranked;
  selected?: boolean;
}>`
  :hover {
    background-color: #f1f1f1;
  }
  ${(p) =>
    p.selected
      ? css<{}>`
          background-color: #ebebeb;
          box-shadow: inset 0 0 2px grey;
        `
      : css<{}>`
          background-color: white;
          box-shadow: none;
        `}
  cursor: pointer;
  border-radius: 5px;
  box-sizing: content-box;
  position: absolute;
  z-index: ${(props) => props.count - props.index};
  height: ${(props) => props.height}px;
  width: 100%;
  flex: 1 1 auto;
  color: black;
  top: ${(props) => props.index * props.height}px;
  transition: top 1.5s cubic-bezier(0.42, 0, 0.58, 1);
  animation: ${(props) => {
      const prevPosition = props.previous?.find(
        (r) => r?.name === props?.items?.[props.index]?.name
      )?.index;
      const prevDayPosition = props?.items?.[props.index]?.prevIndex;
      return enterAnimation(
        props.index * props.height,
        (typeof prevPosition !== 'undefined'
          ? prevPosition
          : typeof prevDayPosition !== 'undefined'
          ? prevDayPosition
          : props.count) * props.height
      );
    }}
    1.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
  display: flex;
  align-items: center;
  > span:first-child {
    > span:first-child {
      display: flex;
      flex-direction: column;
      justify-content: right;
      /* align-items: flex-end; */
      > div:first-child {
        /* border-bottom: 0.2px solid black; */
        /* font-size: 0.8em; */
        white-space: nowrap;
        flex: 0 0 100%;
        width: 100%;
        text-align: right;
        text-decoration: underline;
        text-decoration-color: #aaa;
      }
      > div:nth-child(2) {
        flex: 0 0 100%;
        width: 100%;
        white-space: nowrap;
        font-size: 0.8em;
        text-align: right;
        /* line-height: -3px; */
      }
    }
    flex: 0 0 120px;
    text-align: right;
    font-size: 0.7rem;
    align-items: center;
    display: flex;
    justify-content: end;
    div:nth-child(2) {
      width: 20px;
    }
    div:last-child {
      width: 3px;
      text-align: left;
      font-size: 0.5rem;
    }
    > div > svg {
      margin-right: 3px;
      transform: ${(p) =>
          p.index < (p.items?.[p.index]?.prevIndex || 11)
            ? css`rotate(90deg)`
            : css`rotate(180deg)`}
        scale(1.2);
      > g > path {
        fill: ${(p) =>
          p.index === p.items?.[p.index]?.prevIndex
            ? 'none'
            : p.index < (p.items?.[p.index]?.prevIndex || 11)
            ? colorScale(0.2)
            : colorScale(0.5)};
      }
      > circle {
        stroke: ${(p) =>
          p.index === p.items?.[p.index]?.prevIndex
            ? 'none'
            : p.index < (p.items?.[p.index]?.prevIndex || 11)
            ? colorScale(0.2)
            : colorScale(0.5)};
      }
    }
  }
  > span:last-child {
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    align-items: center;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    > div {
      width: ${(p) =>
        (((p.items?.[p.index]?.count || 0) - (p.items?.[9]?.count || 0)) /
          ((p.items?.[0]?.count || 0) - (p.items?.[9]?.count || 0))) *
          92 +
        5}%;
      height: ${(p) => p.height}px;
      display: block;
      position: relative;
      color: ${(p) => colorScale(p.index / 10)};
      background-color: ${(p) => colorScale(p.index / 10)};
      transition: width 1.5s cubic-bezier(0.42, 0, 0.58, 1),
        background-color 1.5s cubic-bezier(0.42, 0, 0.58, 1);
      align-items: center;
      display: flex;
      > span {
        position: absolute;
        right: -2px;
        transform: translate(100%, 0);
        font-size: 0.5rem;
      }
    }
  }
`;
