import { Header, Button, Wrapper, Labels, StyledSlider, Typography } from './styles';
import moment from 'moment';
import { addMonth, atoms, datediff, monthdiff, parseISOLocal } from 'misc';
import { useRecoilState } from 'recoil';

export type SliderProps = {
  date?: Date;
  month?: string;
  brackets?: string[];
  index?: number;
  setIndex: (ind: number | undefined) => void;
  timeScale?: 'day'|'month'|'year'
};

export default function TimeControls({
  visible = true,
  date,
  month = '2021-07',
  brackets,
  index,
  setIndex,
  timeScale = 'day',
}: { visible?: boolean } & SliderProps) {
  const max = 
    timeScale === 'day'? 
      Math.abs(
        datediff(
          new Date(brackets?.[0] || 0),
          new Date(brackets?.[1] || 0)
        )
      ): 
    timeScale === 'month'?
      monthdiff(
        brackets?.[0] || '2021-05',
        brackets?.[1] || '2021-07'
      ):
    Number(brackets?.[1].split('-')[0]) - Number(brackets?.[0].split('-')[0] ) 
    // [...Array(5).keys()]
    const marks = timeScale === 'day' ? 
      Array.from(Array(
        Math.abs(
              datediff(
                new Date(brackets?.[0] || 0),
                new Date(brackets?.[1] || 0)
              )
            ) + 1
        ).keys()).filter((v,i,arr)=>v % Math.trunc(arr.length / 4) === 0 || i === arr.length - 1): 
      timeScale === 'month' ? 
      Array.from(Array(
        Math.abs(
              monthdiff(
                brackets?.[0] || '2021-05',
                brackets?.[1] || '2021-07'
              )
            ) + 1
        ).keys()):
      Array.from(Array(max+1).keys())
    


  const format = timeScale==='year'?'YYYY': datediff(new Date(brackets?.[0] || 0), new Date(brackets?.[1] || 0)) > (365*2) ? 'MM-YYYY' : 'D MMM, YYYY'
  
  return !visible ? null : (
    <>
      <Header title={`Display visualisations for the selected date`}>
        {timeScale === 'day' ? 'Filter by day:' : 'Filter by month:'}
      </Header>
      <Wrapper>
        <Button onClick={()=>index && setIndex(index-1)}>◀</Button>

        {date && index !== undefined ? (
          <div style={{flex:'1 1 auto', marginTop: '10px'}}>
            <div>
              {brackets && (<>
                <StyledSlider
                  max={max}
                  valueLabelFormat={() =>`${timeScale==='year'? date.getFullYear(): moment(date).format('DD-MM-YYYY')}`}
                  value={index}
                  onChange={(e: any, v: number | number[]) => {
                    if (typeof v === 'number') {
                      setIndex(v);
                    }
                  }}
                />
              </>)}
            </div>
            <Labels>
              {timeScale === 'day' ? 
                marks
                  .map((v, key, arr) =>
                    (
                      <Typography {...{ key:v }}>
                        {moment(
                          new Date(brackets?.[0]||0).setDate(
                                new Date(brackets?.[0] || 0).getDate() + v 
                              )
                        ).format(format)}
                      </Typography>
                    )
                  ):
                timeScale === 'month' ? 
                marks.map((v, key, arr) =>
                    (
                      <Typography {...{ key:v }}>
                        {moment(
                          new Date(brackets?.[0] || 0).setMonth(new Date(brackets?.[0] || 0).getMonth() + v + 1, 1)
                        ).format('MMM')}
                      </Typography>
                    )
                  ):
                marks.map((v, key, arr) =>
                  (
                    <Typography {...{ key:v }}>
                      {brackets?.[0]?.split('-')[0] && Number(brackets?.[0]?.split('-')[0]) + v}
                    </Typography>
                  )
                )}
            </Labels>
          </div>
        ) : null}

        <Button onClick={()=>index!==undefined && index!==max && setIndex(index+1)}>▶</Button>

      </Wrapper>
    </>
  );
}
