import { Layer } from "@urbica/react-map-gl";
import { Expression } from "mapbox-gl";
import { atoms } from "misc";
import { schemaColors } from "misc/recoil/atoms";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

export default function Layers(){

  const [hovered, setHovered] = useRecoilState(atoms.selected);
  const [geography] = useRecoilState(atoms.geography);
  const [countRange] = useRecoilState(atoms.countRange);
  const [data, setData] = useRecoilState(atoms.data);
  const [view, setView] = useRecoilState(atoms.view);

  const [colors, setColors] = useState<Expression|string>('grey')

  useEffect(()=>{
    const mode = view==='absolute'? 'count':'proportion'
    const colorIntLads = [
      'case',
      ['to-boolean', ['feature-state', mode]],
      [
        'interpolate',
        ['linear'],
        ['feature-state', mode],
        ...schemaColors
          .map((c: any, i: any, a: any) => [
            view==='absolute'?
            (data?.[Math.floor(i / a.length * (data?.length||1))]?.count||0)+i:
            (i / a.length), 
            c
          ])
          .flat(),
      ],
      'grey',
    ] as Expression;
    setColors(colorIntLads)
  },[countRange, data, view])
  
  return <>
    <>
      <Layer
        id="lads-fill"
        type="fill"
        source="admin"
        source-layer="lads"
        paint={{'fill-color': colors, "fill-opacity": 0.8}}
        layout={{visibility: geography==='Local Authority Districts'? 'visible':'none'}}
        before="admin-1-boundary-bg"
      />
    </>
    <>
      <Layer
        id="regions-fill"
        type="fill"
        source="admin"
        source-layer="regions"
        paint={{'fill-color': colors, "fill-opacity": 0.8}}
        layout={{visibility: geography==='England Regions'? 'visible':'none'}}
        before="admin-1-boundary-bg"
      />
    </>
    <>
      <Layer
        id="countries-fill"
        type="fill"
        source="admin"
        source-layer="countries"
        paint={{'fill-color': colors, "fill-opacity": 0.8}}
        layout={{visibility: geography==='UK Countries'? 'visible':'none'}}
        before="admin-1-boundary-bg"
      />
    </>
    <Layer 
        id='entries-clusters'
        source='entries-clusters'
        type='circle'
        paint={{
          'circle-color': 'black',
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['get', 'point_count'],
            1, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 16, 15 ],
            20, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 21, 20 ],
            100, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 41, 40 ],
            120, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 46, 45 ]
            ]
        }}
        before="country-boundaries"
      />

      <Layer 
        id='cluster-count'
        type='symbol'
        source='entries-clusters'
        filter={['has', 'point_count']}
        paint={{
          'text-color': 'white',
          'text-opacity-transition': {
            "duration": 0,
            "delay": 0
          }
        }}
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Montserrat SemiBold'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['get', 'point_count'],
            1, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 15, 14 ],
            20, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 17, 16 ],
            100, ['case', ['==', ['get', 'cluster_id'], hovered?.id||''], 19, 18 ]
            ]
        }}
      />
  </>
}