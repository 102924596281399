import { useState } from 'react';
import {
  ButtonRow,
  Header,
  Wrapper,
  Button,
  TextInput,
  InputRow,
  AuthWrapper, LinkRow
} from '../styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useNhostClient } from '@nhost/react';

export default function New() {
  const { auth } = useNhostClient()
  const [credentials, setCredentials] = useState({
    confirmation: '',
    password: '',
  });
  const history = useHistory();
  const { search } = useLocation();
  const [error, setError] = useState<string | null>(null);

  let ticket = new URLSearchParams(search).get('ticket');

  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Create new password</Header>
          <InputRow>
            <TextInput
              required
              type="password"
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              id="password"
              placeholder="Enter new password *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              type="password"
              value={credentials.confirmation}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  confirmation: e.target.value,
                })
              }
              id="confirmation"
              placeholder="Confirm new password *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              disabled={
                credentials.password.length < 6 ||
                credentials.password !== credentials.confirmation
              }
              onClick={() => {
                if (ticket && credentials.password && credentials.confirmation) {
                  auth
                    .changePassword({newPassword: credentials.password, ticket})
                    .then((res) => {
                      history.push('/authentication/login');
                    })
                    .catch((err) => {
                      console.log(err);
                      setError(err?.response?.data?.message?.replaceAll('"', '') || err.message)
                    });
                }else{
                  setError(ticket? 'Please, enter new password and confirm it': 'No ticket found')
                }
              }}
            >
              CHANGE
            </Button>
          </ButtonRow>
          <LinkRow>
            <div />
            <Link to="/authentication/login">Back</Link>
          </LinkRow>
        </Wrapper>
      </AuthWrapper>
    </>
  );
}
