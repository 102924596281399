import { AuthWrapper, Header, Wrapper } from '../styles';

export default function EmailVerify() {
  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Verify email</Header>
          <div style={{textAlign:'center'}}>
            Thanks for registering. Please check your inbox and approve your
            email. If you don't see the email, please check your spam folder.
          </div>
        </Wrapper>
      </AuthWrapper>
    </>
  );
}
