import styled, { css } from 'styled-components/macro';
import { TextField } from 'components/Inputs/TextInput';

export const AuthWrapper = styled.div.attrs(({ children }) => ({
  children: (
    <>
      <div>{children}
      <br/>
      Personal data you provide in completing this form 
      will be used in accordance with the Privacy Notice 
      at the foot of this registration page. <br/><br/><a href="/privacy_policy.pdf">Privacy and Cookies</a>
      </div>
      </>
  ),
}))`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    min-width: 300px;
    max-width: 400px;
  }
`,

Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`,

IconStyle = css<{}>`
  flex: 0 0 89px;
  width: 89px;
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-radius: 50%;
`,

Header = styled.h1`
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem 1rem 1rem;
`,

ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`,

LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`,

Button = styled.button`
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1rem;
  font-weight: 600;
  height: 42px;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #a7a7a7;
  border-radius: 4px;
  margin: 0rem 0rem 0.5rem 0.5rem !important;
  transition: background-color 0.2s ease-in-out;
  &:first-child {
    flex: 1 1 auto;
    margin: 0rem 0rem 0rem 0rem !important;
  }
  :hover{
    background-color: #8b8b8b;
  }
  :active{
    background-color: #6d6d6d;
  }
`,

InputRow = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`,

TextInput = styled(TextField)`
  border-radius: 5px;
`;
