import { atoms } from "misc";
import { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";
import { useRecoilState } from "recoil";
import AccountReviewed from 'components/Auth/Account/Reviewed';

export const ApprovedProtectedRoute = ({
  children,
  ...rest
}: {
  children: ReactNode;
  path: string;
  exact?: boolean;
}) => {
  
  const [user] = useRecoilState(atoms.user);
  return (user?.id && user.activated) ? (
    <Route {...rest}>
        {children}
    </Route>
  ) : 
  (!user?.id) ? (
    <Route {...rest}>
      <Redirect to="/authentication/login" />
    </Route>
  ):(
    <Route {...rest}>
      <AccountReviewed/>
    </Route>
  );
};