import styled, { css } from 'styled-components/macro';

export const ButtonStyled = styled.div<{noshadow?:boolean}>`
    bottom: 1.5rem;
    right: 1rem;
    position: fixed;
    button {
      min-width: 3rem;
      min-height: 3rem;

      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 16px 16px rgb(0 0 0 / 6%);
      ${p=>p.noshadow && 
        css`box-shadow: none;`
      }

      background: white;
      opacity: 1;
      :hover {
        background: #eee;
      }
    }
  `,

  SubMenu = styled.div<{open?:boolean}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    height: 3rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    padding-right: 3rem;
    display: flex;
    align-items: center;
    color: '#5e5e5e';
    font-weight: 600;
    max-width: ${p=>p.open ? '20rem' : '2rem'};
    transition: max-width 0.5s ease-in-out;
    /* width: 6rem; */
    height: auto;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 16px 16px rgb(0 0 0 / 6%);
    opacity: 1;
    >div{
      padding-left: 1rem;

      padding-right: 0.5rem;
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      >span:first-child{
        font-size: 1rem;
        cursor: pointer;

      }
      >span:last-child{
        font-size: 8px;
        cursor: pointer;

      }
    }
  `,
  
  LogoutButton = styled.div`
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    margin-left: 0.1rem;
    margin-right: 0.5rem;

    justify-content: center;
    cursor: pointer;
    background-color: rgb(0 0 0 / 0%);
    transition: background-color 0.2s ease-in-out;
    :hover{
      background-color: rgb(0 0 0 / 15%);
    }
  `,

  AppWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    display: flex;
  `;
