import styled, { css } from 'styled-components'
import { Typography } from '@mui/material';

export const
PanelWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  justify-content: start;
  margin: 1rem 1rem 1rem 2rem;
  padding: 1rem;
  background-color: white;
  height: 120px;
  flex-direction: row;
  z-index: 0;
  box-shadow: inset 0px 4px 0px #ffe964, 0px 4px 4px rgb(0 0 0 / 25%),
      0px 16px 16px rgb(0 0 0 / 6%);
`,

Info = styled(Typography).attrs({
  variant: 'caption',
})`
  margin-top: -5px !important;
  white-space: nowrap; 
  line-height: 0.7rem !important;
  font-size: 0.5rem !important;
`,

Cell = styled.div`
  text-align: center;
  display: flex;
  flex: 0 1 100px;
  align-items: top;
  padding: 0.5rem;
  align-items: center;
  max-height: 100%;
  flex-direction: column;
`,

Divider = styled.div`
  height: calc(100% - 1rem);

  width:1px;
  border-left: 1px solid rgba(0,0,0,0.2);
`,

Image = styled.img<{selected: boolean}>`
  cursor: pointer;
  height: 60px;
  margin-bottom: 0.5rem;
  border-radius: 0.4rem;
  opacity: 1;
  transition: border 0.2s;
  :hover {
    opacity: 0.9;
  }
  ${p=>p.selected ? 
    css`border: solid 4px rgba(0,0,0,0.15);`:
    css`border: solid 4px rgba(0, 0, 0, 0);`
  }
`
