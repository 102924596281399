import TimeControls from 'components/TimeControls';
import { useYearRangeQuery } from 'generated';
import { atoms, datediff } from 'misc';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

export default function Sliders() {

  const {data} = useYearRangeQuery()
  const [index, setIndex] = useState<number|null>(null);
  const [timePeriod] = useRecoilState(atoms.timePeriod);
  const [date, setDate] = useRecoilState(atoms.date);
  const [brackets, setBrackets] = useState<string[]>([]);
  
  useEffect(()=>{
    if(data){
      if(timePeriod==='Last 30 days'){
        const min = data.rolling.aggregate?.min?.date || '2021-01-01';
        const max = data.rolling.aggregate?.max?.date || new Date().toISOString().split('T')[0];
        setBrackets([min, max])
        const distance = datediff(new Date(min), new Date(max))
        setIndex(distance - 30)
        setDate(new Date(max))
      }else if(timePeriod==='Year-to-date'){
        const min = data.geo.aggregate?.min?.year || '2021';
        const max = data.geo.aggregate?.max?.year || new Date().toISOString().split('T')[0];
        setBrackets([min+'-01-01', max+'-01-01'])
        setIndex(Number(max) - Number(min) - 1)
        setDate(new Date(max+'-01-01'))
      }
    }
  },[data, timePeriod])

  function setValue(v:number){
    setIndex(v)
    setDate(new Date(new Date(brackets[0]).getTime() + v * 24 * 60 * 60 * 1000))
  }

  function setValueYear(v:number){
    setIndex(v)
    setDate(new Date(Number(brackets?.[0]?.split('-')[0])+v + '-01-01'))
  }

  return <>
    {data && <>
      {
        timePeriod==='Last 30 days' ?
          <TimeControls brackets={brackets} date={date} index={index||0} setIndex={(v)=>v!==null && v!==undefined && setValue(v)} timeScale='day'/>:
        timePeriod==='Year-to-date' ?
          <TimeControls brackets={brackets} date={date} index={index||0} setIndex={(v)=>v!==null && v!==undefined && setValueYear(v)} timeScale='year'/>: null
      }
    </>}
  </>
}
