import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import ComboBox from './SearchField';
import { atoms } from 'misc';
import { useRecoilState } from 'recoil';
import { Geographies, TimePeriods } from 'misc/recoil/atoms';


export default function Filters() {
  const [geography, setGeographies] = useRecoilState(atoms.geography);
  const [timePeriod, setTimePeriod] = useRecoilState(atoms.timePeriod);

  const handleChange = (event: SelectChangeEvent) => {
    setGeographies(event.target.value as Geographies);
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    setTimePeriod(event.target.value as TimePeriods);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="geography">Geography</InputLabel>
        <Select
          labelId="geography"
          id="geography"
          value={geography}
          size='small'
          label="Geography"
          onChange={handleChange}
        >
          <MenuItem value="UK Countries">UK Countries</MenuItem>
          <MenuItem value="England Regions">England Regions</MenuItem>
          <MenuItem value="Local Authority Districts">Local Authority Districts</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{marginTop: '1rem', marginBottom: '1rem'}}>
        <InputLabel id="time-period">Time Period</InputLabel>
        <Select
          labelId="time-period"
          id="time-period"
          value={timePeriod}
          size='small'
          label="Time Period"
          onChange={handleTimeChange}
        >
          <MenuItem value="Last 30 days">Last 30 days</MenuItem>
          <MenuItem value="Year-to-date">Year-to-date</MenuItem>
          <MenuItem value="Full historical data">Full historical data</MenuItem>
        </Select>
      </FormControl>
      <ComboBox />
    </>
  );
}