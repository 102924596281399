import { AuthWrapper, Header, Wrapper } from '../styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Approve() {
  const { search } = useLocation();
  let ticket = new URLSearchParams(search).get('ticket');
  let approved = new URLSearchParams(search).get('approved');

  const [status, setStatus] = useState<
    | { current: 'Loading' }
    | { current: 'No-ticket' }
    | { current: 'Activated'; data: any }
    | { current: 'Refused'; data: any }
    | { current: 'Error'; data: any }
  >(ticket ? { current: 'Loading' } : { current: 'No-ticket' });

  useEffect(() => {
    if (ticket && approved !== null) {
      fetch(
        `${
          window.location.origin.includes('localhost')
            ? 'http://localhost:4000'
            : 'https://mvooxvsnuvvriydyrxvq.nhost.run'
        }/v1/functions/activate?ticket=${ticket}&activated=${approved}`
      )
        .then((data) => {
          if (data?.ok) {
            setStatus({
              current: approved === 'true' ? 'Activated' : 'Refused',
              data,
            });
          } else {
            setStatus({ current: 'Error', data });
          }
        })
        .catch((err) => {
          setStatus({ current: 'Error', data: err });
        });
    }
  }, [ticket, approved]);

  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>{status.current}</Header>
          <div>{
            status.current==='Activated'?
               "Account was activated successfully.":
            status.current==='Loading'?
              "Please wait...":
            status.current==='No-ticket'?
              "No ticket found.":
            status.current==='Refused'?
              "Account activation was refused.":
              "Unknown error happened."
            }</div>
        </Wrapper>
      </AuthWrapper>
    </>
  );
}
