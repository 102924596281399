import { useContext, useState } from 'react';
import {
  ButtonRow,
  Header,
  Wrapper,
  Button,
  TextInput,
  InputRow,
  AuthWrapper,
  LinkRow,
} from '../styles';
import { Link, useHistory } from 'react-router-dom';
import { Snackbar } from '@mui/material'
import styled from 'styled-components'
import { useNhostClient  } from '@nhost/react'
import { useUserLazyQuery } from 'generated';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';

const Uppercase = styled.div`
  *>:first-letter {text-transform: uppercase}
`

export default function Login() {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState<string | null>(null);
  const { auth } = useNhostClient()
  const [getUser] = useUserLazyQuery({ fetchPolicy: 'no-cache' });
  const [, setUser] = useRecoilState(atoms.user);
  const router = useHistory()
  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Login</Header>
          <InputRow>
            <TextInput
              required
              value={credentials.email}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              id="email"
              placeholder="Email *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              type="password"
              id="password"
              placeholder="Password *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              onClick={() =>
                auth
                  ?.signIn(credentials)
                  .then(async (response) => {
                    if(response?.error?.error==='already-signed-in'){
                      const user = auth.getUser();
                      try{
                        const status = await getUser({ variables: { user_id: user?.id } });
                        const update = {...user, ...status.data?.user||{}} as any
                        setUser(update);
                        // router.push('/')
                      }catch(e){
                        console.log('Error', e);
                      }

                      
                    }else if (!response.session) {
                      console.log('Please, check your email');
                    }
                    // router.push('/')
                  })
                  .catch((err) => {
                    setError(err?.response?.data?.message?.replaceAll('"', '') || err.message)
                  })
              }
            >
              Login
            </Button>
          </ButtonRow>
          <LinkRow>
            <Link to="/authentication/forgot-password">Recover password</Link>
            <Link to="/authentication/register">Register</Link>
          </LinkRow>
        </Wrapper>
      </AuthWrapper>
      <Uppercase>
        <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
          open={!!error}
          onClose={()=>setError(null)}
          message={error}
        />
      </Uppercase>
    </>
  );
}
