import { useRollingWindowQuery } from 'generated';
import { atoms, usePrevious } from 'misc';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Rank } from '../YearlyBars/styles';


type Data = {
  name: string,
  count: number,
  index: number,
  prevIndex: number,
}[]

export default function RaceChart() {
  const [date, setDate] = useRecoilState(atoms.date);

  const {data:raw, error} = useRollingWindowQuery({variables:{
    date: date?.toISOString().split('T')[0]||new Date().toISOString().split('T')[0], 
    prevDate: date? new Date(date?.getTime() - 24 * 60 * 60 * 1000).toISOString().split('T')[0]: new Date().toISOString().split('T')[0]
  }})

  const [data, setData] = useState<Data>([])
  const prevData = usePrevious(data, { unique: true });
  const prevPrevData = usePrevious(prevData, { unique: true });

  useEffect(()=>{
    if(raw){
      
      const d = raw.skills.map((d,i)=>{
        const prev = raw.prevSkills.find(d2=>d2.name===d.name)
        const prevIndex = raw.prevSkills.findIndex(d2=>d2.name===d.name)
        return {
          name: d.name||'',
          count: d.count,
          index: i,
          prevIndex: prevIndex || i,
        }
      }
      ).sort((a,b)=>b.count-a.count)
      setData(d)
    }
  },[raw])

  return <div style={{display:'flex', flexDirection:'column', height: '100%'}}>
    <div style={{flex:'1 1 auto', marginTop: '1rem'}}>

      <Rank
        items={data||[]}
        previous={prevPrevData||[]}
        itemHeight={30}
        length={10}
      />
      
    </div>
  </div>
}
