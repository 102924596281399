import { useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';
import { MapGL } from './styles';
import {
  AttributionControl,
  MapContext,
  NavigationControl,
} from '@urbica/react-map-gl';
import { Map } from 'mapbox-gl';
import { useLocation } from 'react-router-dom';
import Sources from './Sources';
import Layers from './Layers';
import FitView from './FitView';
import DynamicStyling from './DynamicStyling';
import { LngLatBoundsLike } from 'mapbox-gl'

export default function MapComponent() {
  const [viewport, setViewport] = useRecoilState(atoms.viewport);

  const [, setSelected] = useRecoilState(atoms.selected);
  const [cursor] = useRecoilState(atoms.cursor);

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (event) => {
        const keyName = event.key;
        if (keyName === 'Control') {
          return;
        }
        if (event.ctrlKey && keyName === 'z') {
          setSelected(null);
        }
      },
      false
    );
  }, []);

  const path = useLocation().pathname;

  return (
    <>
      <MapGL
        mapStyle="mapbox://styles/switch9/cla9ojggd00jt15ph1q035q9c"
        accessToken="pk.eyJ1Ijoic3dpdGNoOSIsImEiOiJjamozeGV2bnkxajV2M3FvNnpod3h4ODlpIn0.CTt2IXV8II6finbTlxEddg"
        onViewportChange={setViewport}
        cursorStyle={cursor}
        attributionControl={false}
        viewportChangeMethod="easeTo"
        hash
        minZoom={3}
        maxZoom={9.9}
        bounds={[[-7.57216793459, 49.959999905], [1.68153079591, 58.6350001085]] as LngLatBoundsLike}
        {...{path}}
        {...viewport}
      >
        <AttributionControl compact={true} position="bottom-left" />
        <MapContext.Consumer>
          {(map: Map) => <DynamicStyling {...{map}}/>}
        </MapContext.Consumer>
        <NavigationControl showCompass={false} position="bottom-right" />
        <MapContext.Consumer>
          {(map:Map)=><FitView {...{map}}/>}
        </MapContext.Consumer>
        <Sources/>
        <Layers/>
      </MapGL>

        
        {/* <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div> */}
      
    </>
  );
}
