import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  components: {

    MuiSwitch: {
      styleOverrides:{
        track: {
          backgroundColor: '#ccc',
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides:{
        colorSecondary: {
          '& $notchedOutline': {
            borderColor: 'white',
          },
          '&:hover $notchedOutline': {
            borderColor: 'white',
          },
          '&$focused $notchedOutline': {
            borderColor: 'white',
          },
        },
      }
    },
  },
});
