import { atoms } from 'misc';
import { useRecoilValue } from 'recoil';
import { AuthWrapper, Header, Wrapper } from '../styles';

export default function Verified() {

  const user = useRecoilValue(atoms.user);

  return (
    <>
      <AuthWrapper>
        <Wrapper>
          <Header>Processing</Header>
          <div style={{textAlign:'center'}}>
          Hi {user?.metadata?.first_name} {user?.metadata?.last_name}! 
          Your account is currently reviewed by our administrators.
          There are no further steps needed to be taken from your side. 
          You will receive an email with the further instructions within next 24 hours.
          If you have any questions on the meanwhile, please contact us at <a href="mailto:registration@oxdatatech.com">registration@oxdatatech.com</a>.
          </div>
        </Wrapper>
      </AuthWrapper>
    </>
  );
}
