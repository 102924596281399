import Slider from '@mui/material/Slider';
import MuiTypography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import styled from 'styled-components/macro';

export const 
Button = styled.button`
  width:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  background-color:white;
  transition: background-color 0.2s ease-in-out;
  &:hover{
    background-color: #f0f0f0;
  }
  :first-child{
    margin-right: 15px;
  }
  :last-child{
    margin-left: 15px;
  }
`,

Header = styled.h6`
    font-size: 12;
    line-height: 1.2;
    font-weight: 500;
    margin: 0 0 1rem 0;
    margin-top: 2rem;
  `,
  Wrapper = styled.div`
    margin-top: -28px;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: white;
    display: flex;
  `,
  Labels = styled.div`
    margin: -0.8rem 0.5rem;
    display: flex;
    justify-content: space-between;
    color: black;
    flex-basis: 100%;
    > p {
      font-size: 0.7rem;
    }
  `,
  Typography = styled(MuiTypography).attrs({
    variant: 'body2',
  })`
    font-size: 0.7rem;
    writing-mode: vertical-rl;
    transform: rotate(270deg);
  `,

  MuiSlider = styled(Slider)(({ theme }) => ({
    
    color: 'grey',
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: '1rem',
      width: '0.2rem',
      backgroundColor: '#000',
      opacity: 0.4,
      borderRadius: 0,
      marginTop: '0rem',
      marginLeft: '-0.1rem',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // boxShadow: BoxShadowActive,
      '&:focus, &:hover, &$active': {
        // boxShadow: BoxShadowInactive,
        '@media (hover: none)': {
          // boxShadow: BoxShadowActive,
        },
      },
    },
    '& .MuiSlider-active': {},
    '& .MuiSlider-track': {
      height: 1.5,
    },
    '& .MuiSlider-rail': {
      height: 1.5,
      opacity: 0.5,
      backgroundColor: '#666',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#666',
      height: 0,
      width: 0,
      marginTop: 0,
    },
    '& .MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: '#666',
      color: 'white',
      height: '2rem',
      '&:before': {
        // display: 'none',
      },
      '& *': {
        background: 'transparent',
      },
    },
  })),

  StyledSlider = styled(MuiSlider).attrs({
    'aria-label': 'Population trajectories over time',
    valueLabelDisplay: 'auto',
    step: 1,
    min: 0,
  })`
    margin-top: 1.2rem;
    > span > span > span {
      transform-origin: right center !important;
    }
  `;
